import styled from "styled-components";
import React, { useEffect } from "react";
import { CenteredWithPadding } from "./content";
import { H1 } from "../elements/headers";
import { pageTitleFormatter } from "../../utils/formatters";

const HeaderContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 150px;

  background: #4498d8;
`;

const MainContentContainer = styled.div`
  position: absolute;
  width: 100%;
  padding-bottom: 85px;

  color: #ffffff;
  z-index: 2;
`;

const Container = styled.div`
  margin: 0 15px;
  padding: 15px;

  background: #ffffff;
  border-radius: 5px;
  color: #22252d;
`;

const PageWrapper: React.FC<{ title: string; children: any }> = ({
  title,
  children
}) => {
  useEffect(() => {
    document.title = pageTitleFormatter(title);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <CenteredWithPadding>
        <H1>{title}</H1>
      </CenteredWithPadding>
      <Container>{children}</Container>
    </>
  );
};

export { HeaderContainer, MainContentContainer, Container, PageWrapper };
