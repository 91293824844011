import styled from "styled-components";
import React from "react";
import { Centered } from "./content";
import { PrimaryButton } from "../elements/forms";

const EmptyStateText = styled.div`
  padding: 25px 0;
  margin-bottom: 15px;

  border: 3px dotted #dddddd;
  border-radius: 5px;
`;

const Icon = styled.i`
  margin-bottom: 25px;

  font-size: 6rem;
  color: #dddddd;
`;

const EmptyState: React.FC<{
  text: string;
  buttonText: string;
  icon: string;
  onClick: () => void;
}> = ({ text, buttonText, icon, onClick }) => (
  <Centered>
    <EmptyStateText>
      <div>
        <Icon className={icon} />
      </div>
      {text}
    </EmptyStateText>
    <div>
      <PrimaryButton onClick={onClick}>{buttonText}</PrimaryButton>
    </div>
  </Centered>
);

export { EmptyState };
