import React, { useState } from "react";
import { History } from "history";
import { withRouter } from "react-router-dom";

import { H1 } from "./elements/headers";
import { Panel } from "./widgets/panel";
import { InputControl } from "./elements/inputControl";
import { PrimaryButton } from "./elements/forms";
import { isValidEmail } from "../utils/validators";
import { LogoLink } from "./elements/links";
import { P, CenteredContent } from "./widgets/content";
import { Snackbar } from "./widgets/snackbar";
import { Link } from "./elements/links";
import { createUser } from "../services/users";

export const SignUp: React.FC<{ history: History }> = ({ history }) => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [nameError, setNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passwordMismatchError, setPasswordMismatchError] = useState("");

  const [signUpError, setSignUpError] = useState("");

  const attemptSignUp = async (): Promise<void> => {
    const hasValidEmail = isValidEmail(email);
    const hasValidPassword = !!password.length;
    const hasValidConfirmPassword =
      !!confirmPassword.length && password === confirmPassword;

    setEmailError("");
    setNameError("");
    setPasswordError("");
    setPasswordMismatchError("");

    if (!hasValidEmail) {
      setEmailError("Invalid email");
    }

    if (!name.length || name.split(" ").length < 2) {
      setNameError("Full name is required");
    }

    if (!hasValidPassword) {
      setPasswordError("Invalid password");
    }

    if (!hasValidConfirmPassword) {
      setPasswordMismatchError("Password's do not match");
    }

    if (!hasValidEmail || !hasValidPassword || !hasValidConfirmPassword) {
      return;
    }

    await createUser({ email, password, name }).catch(error => {
      const errorMessage = getErrorMessage(error.code);

      setSignUpError(errorMessage);
    });

    history.push("/login");
  };

  const getErrorMessage = (code: string): string => {
    switch (code) {
      case "auth/invalid-password":
        return "The password provided is invalid. It must be at least 6 characters.";
      case "auth/user-disabled":
        return "The account provided is currently disabled.";
      case "auth/invalid-email":
        return "The email provided is invalid.";
      default:
        return "There was a problem creating your account with the provided credentials.";
    }
  };

  return (
    <div>
      <LogoLink />
      <Panel>
        <H1>Sign up</H1>
        <P>Enter an email and password below to create an account.</P>
        <form onSubmit={e => e.preventDefault()}>
          <InputControl
            errorMessage={nameError}
            type="text"
            placeholder="Full name"
            onChange={(e: any) => setName(e.target.value)}
            value={name}
          />
          <InputControl
            errorMessage={emailError}
            type="text"
            placeholder="Email"
            onChange={(e: any) => setEmail(e.target.value)}
            autoComplete="email"
          />
          <InputControl
            errorMessage={passwordError}
            type="password"
            placeholder="Password"
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <InputControl
            errorMessage={passwordMismatchError}
            type="password"
            placeholder="Confirm Password"
            onChange={(e: any) => setConfirmPassword(e.target.value)}
          />
          <PrimaryButton data-testid="SignUp__button" onClick={attemptSignUp}>
            Sign up
          </PrimaryButton>
        </form>
        <CenteredContent>
          Already have an account? <Link to="/login">Login</Link>
        </CenteredContent>
      </Panel>
      <Snackbar
        open={!!signUpError.length}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        message={signUpError}
        autoHideDuration={6000}
        onClose={() => setSignUpError("")}
      />
    </div>
  );
};

export default withRouter(SignUp);
