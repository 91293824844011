import React, { useEffect, useState } from "react";
import { ProgressBar } from "./components/widgets/progressBar";
import {
  HeaderContainer,
  MainContentContainer
} from "./components/widgets/page";
import { Route, Switch } from "react-router";
import Dashboard from "./components/dashboard";
import { Bookings } from "./components/bookings";
import { Packages } from "./components/packages";
import { Field } from "./components/field";
import { Equipment } from "./components/equipment";
import { Customers } from "./components/customers";
import { AccountSettings } from "./components/accountSettings";
import { CenteredContent } from "./components/widgets/content";
import { H1 } from "./components/elements/headers";
import { Navigation } from "./components/navigation";
import { pageTitleFormatter } from "./utils/formatters";

const AccessibleRoutes: React.FC<{ history: any; userDetails: any }> = ({
  history,
  userDetails
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [wasLoading, setWasLoading] = useState(false);
  const [loadingKey, setLoadingKey] = useState(null);

  useEffect(
    () =>
      history.listen(() => {
        setLoadingKey(Date.now());
      }),
    // eslint-disable-next-line
    []
  );

  useEffect(() => {
    if (isLoading !== wasLoading) {
      setWasLoading(isLoading);
    }

    if (isLoading && !wasLoading) {
      setLoadingKey(Date.now());
    }
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <>
      <ProgressBar isLoading={isLoading} key={loadingKey} />
      <HeaderContainer />
      <MainContentContainer>
        <Switch>
          <Route
            path="/dashboard"
            render={(props: any) => {
              document.title = pageTitleFormatter("Dashboard");

              return <Dashboard {...props} />;
            }}
          />
          <Route
            path="/bookings"
            render={(props: any) => {
              document.title = pageTitleFormatter("Bookings");

              return (
                <Bookings
                  {...props}
                  setIsLoading={setIsLoading}
                  userDetails={userDetails}
                />
              );
            }}
          />
          <Route
            path="/packages"
            render={(props: any) => {
              document.title = pageTitleFormatter("Packages");

              return (
                <Packages
                  {...props}
                  setIsLoading={setIsLoading}
                  userDetails={userDetails}
                />
              );
            }}
          />
          <Route
            path="/field"
            render={(props: any) => {
              document.title = pageTitleFormatter("Field");

              return <Field {...props} setIsLoading={setIsLoading} />;
            }}
          />
          <Route
            path="/equipment"
            render={(props: any) => {
              document.title = pageTitleFormatter("Equipment");

              return (
                <Equipment
                  {...props}
                  setIsLoading={setIsLoading}
                  userDetails={userDetails}
                />
              );
            }}
          />
          <Route
            path="/customers"
            render={(props: any) => {
              document.title = pageTitleFormatter("Customers");

              return (
                <Customers
                  {...props}
                  setIsLoading={setIsLoading}
                  userDetails={userDetails}
                />
              );
            }}
          />
          <Route
            path="/account-settings"
            render={(props: any) => {
              document.title = pageTitleFormatter("Account settings");

              return (
                <AccountSettings
                  {...props}
                  setIsLoading={setIsLoading}
                  userDetails={userDetails}
                />
              );
            }}
          />
          <Route
            path="*"
            render={() => (
              <CenteredContent>
                <H1>Not found</H1>
              </CenteredContent>
            )}
          />
        </Switch>
      </MainContentContainer>
      <Navigation />
    </>
  );
};

export { AccessibleRoutes };
