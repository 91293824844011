import React, { useEffect, useState } from "react";
import { CenteredWithPadding } from "./widgets/content";
import { H1, H4 } from "./elements/headers";
import { Container } from "./widgets/page";
import { InputControl } from "./elements/inputControl";
import styled from "styled-components";
import { PrimaryButton } from "./elements/forms";
import {
  addCustomer,
  getCustomer,
  updateCustomer
} from "../services/customers";

const DetailsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
`;

const CustomersAdd: React.FC<{
  title: string;
  match: any;
  history: any;
  setIsLoading: (isLoading: boolean) => void;
  userDetails: any;
}> = ({ title, match, history, setIsLoading, userDetails }) => {
  const [customerId, setCustomerId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");

  const getCustomerToEdit = async () => {
    setIsLoading(true);
    const customer = await getCustomer(
      userDetails.fieldId,
      match.params.customerId
    );
    setIsLoading(false);

    setCustomerId(customer.id);
    setFirstName(customer.firstName);
    setLastName(customer.lastName);
    setPhoneNumber(customer.phoneNumber);
    setEmail(customer.email);
  };

  useEffect(() => {
    if (match.params.customerId) {
      getCustomerToEdit();
    }
    // eslint-disable-next-line
  }, []);

  const saveCustomer = async () => {
    setIsLoading(true);
    if (customerId) {
      await updateCustomer(userDetails.fieldId, {
        id: customerId,
        firstName,
        lastName,
        phoneNumber,
        email
      });
    } else {
      await addCustomer(userDetails.fieldId, {
        firstName,
        lastName,
        phoneNumber,
        email
      });
    }
    setIsLoading(false);

    history.push("/customers");
  };

  return (
    <div>
      <CenteredWithPadding>
        <H1>{title}</H1>
      </CenteredWithPadding>
      <Container>
        <H4>Details</H4>
        <DetailsContainer>
          <InputControl
            type="text"
            placeholder="First name"
            value={firstName}
            onChange={(e: any) => setFirstName(e.target.value)}
          />
          <InputControl
            type="text"
            placeholder="Last name"
            value={lastName}
            onChange={(e: any) => setLastName(e.target.value)}
          />
        </DetailsContainer>
        <H4>Contact information</H4>
        <InputControl
          type="tel"
          placeholder="Phone number"
          value={phoneNumber}
          onChange={(e: any) => setPhoneNumber(e.target.value)}
        />
        <InputControl
          type="text"
          placeholder="Email"
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
        />
        <PrimaryButton onClick={saveCustomer}>Save</PrimaryButton>
      </Container>
    </div>
  );
};

export { CustomersAdd };
