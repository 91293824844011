import React, { useState } from "react";
import { withRouter, Route, RouteComponentProps } from "react-router-dom";
import qs from "query-string";

import firebase from "../firebase";
import { H1 } from "./elements/headers";
import { Panel } from "./widgets/panel";
import { InputControl } from "./elements/inputControl";
import { PrimaryButton } from "./elements/forms";
import { LogoLink } from "./elements/links";
import { P } from "./widgets/content";
import { Snackbar } from "./widgets/snackbar";
import { Link } from "./elements/links";

export const PasswordReset: React.FC<RouteComponentProps> = ({
  history,
  location
}) => {
  const { token } = qs.parse(location.search);

  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [passwordError, setPasswordError] = useState("");
  const [passwordMismatchError, setPasswordMismatchError] = useState("");

  const [
    passwordResetError,
    setPasswordResetError
  ] = useState<React.ReactElement | null>(null);

  const attemptPasswordReset = async (): Promise<void> => {
    if (!token) {
      setPasswordResetError(
        <div>
          Cannot reset password at this time. Please request a new password
          reset email.
        </div>
      );

      return;
    }

    const hasValidPassword = !!password.length;
    const hasValidConfirmPassword =
      !!confirmPassword.length && password === confirmPassword;

    setPasswordError("");
    setPasswordMismatchError("");

    if (!hasValidPassword) {
      setPasswordError("Invalid password");
    }

    if (!hasValidConfirmPassword) {
      setPasswordMismatchError("Password's do not match");
    }

    if (!hasValidPassword || !hasValidConfirmPassword) {
      return;
    }

    try {
      await firebase.auth().signOut();
      await firebase.auth().signInWithCustomToken(token as string);

      const resetPassword = firebase.functions().httpsCallable("resetPassword");

      await resetPassword({ password });
      await firebase.auth().signOut();

      history.push("/password-reset/complete");
    } catch (e) {
      const message = getErrorMessage(e.code);
      setPasswordResetError(<div>{message}</div>);
    }
  };

  const getErrorMessage = (code: string): string => {
    switch (code) {
      case "auth/invalid-password":
        return "The password provided is invalid. It must be at least 6 characters.";
      default:
        return "There was a problem resetting your password.";
    }
  };

  return (
    <div>
      <LogoLink />
      <Panel>
        <Route
          exact
          path="/password-reset"
          render={() => (
            <>
              <H1>New password</H1>
              <P>Enter a new password for your account.</P>
              <form onSubmit={e => e.preventDefault()}>
                <InputControl
                  errorMessage={passwordError}
                  type="password"
                  placeholder="New password"
                  onChange={(e: any) => setPassword(e.target.value)}
                />
                <InputControl
                  errorMessage={passwordMismatchError}
                  type="password"
                  placeholder="Confirm new password"
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                />
                <PrimaryButton
                  data-testid="PasswordReset__button"
                  onClick={attemptPasswordReset}
                >
                  Submit
                </PrimaryButton>
              </form>
            </>
          )}
        />
        <Route
          path="/password-reset/complete"
          render={() => (
            <>
              <H1>Password updated</H1>
              <P>
                Your password has been changed. Please return to the{" "}
                <Link to="/login">login page</Link>.
              </P>
            </>
          )}
        />
      </Panel>
      <Snackbar
        open={!!passwordResetError}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        message={passwordResetError}
        autoHideDuration={6000}
        onClose={() => setPasswordResetError(null)}
      />
    </div>
  );
};

export default withRouter(PasswordReset);
