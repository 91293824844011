import styled from "styled-components";

const Centered = styled.div`
  text-align: center;
`;

const CenteredContent = styled.div`
  margin-bottom: 10px;
  width: 100%;

  text-align: center;
`;

const CenteredWithPadding = styled(CenteredContent)`
  padding-top: 10px;
`;

const P = styled.p`
  margin: 0 0 10px 0;
  line-height: 1.35;
`;

const BasicP = styled(P)`
  margin: 0;
`;

export { Centered, CenteredContent, CenteredWithPadding, P, BasicP };
