import React from "react";
import styled from "styled-components";

const ErrorMessage = styled.div`
  margin-bottom: 10px;

  color: #ed4a4a;
`;

export interface InputProps extends React.HTMLProps<HTMLInputElement> {
  hasError?: boolean;
  disabled?: boolean;
}

const Input = styled.input<InputProps>`
  margin-bottom: ${props => (props.hasError ? "5px" : "15px")};
  padding: 10px;
  width: 100%;

  background: #eeeeee;
  border: 1px solid ${props => (props.hasError ? "#ED4A4A" : "#e1e1e1")};
  border-radius: 5px;
  color: ${props => (props.hasError ? "#ED4A4A" : "inherit")};
  font-size: 1rem;
  font-family: "Roboto", sans-serif;

  ${props => (props.disabled ? "opacity: .5;" : "")}
`;

const TextArea = styled.textarea<InputProps>`
  margin-bottom: ${props => (props.hasError ? "5px" : "15px")};
  padding: 10px;
  width: 100%;

  background: #eeeeee;
  border: 1px solid ${props => (props.hasError ? "#ED4A4A" : "#e1e1e1")};
  border-radius: 5px;
  color: ${props => (props.hasError ? "#ED4A4A" : "inherit")};
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  resize: none;

  ${props => (props.disabled ? "opacity: .5;" : "")}
`;

const CheckboxWrapper = styled.input<InputProps>`
  margin: 0;
`;

const Checkbox = (props: any) => <CheckboxWrapper type="checkbox" {...props} />;

const PrimaryButton = styled.button<{ disabled?: boolean }>`
  margin: 15px 0;
  padding: 10px;
  width: 100%;

  background: #4498d8;
  border: none;
  border-radius: 5px;
  color: #ffffff;
  font-size: 1rem;

  ${props => (props.disabled ? "opacity: .5;" : "")}
`;

const CondensedPrimaryButton = styled(PrimaryButton)`
  margin-top: 0;
  padding: 3px 10px;

  ${props => (props.disabled ? "opacity: .5;" : "")}
`;

const SecondaryButton = styled(PrimaryButton)`
  background: #54a24b;
`;

const CondensedSecondaryButton = styled(SecondaryButton)`
  margin-top: 0;
  padding: 3px 10px;
`;

const Select = styled.select<InputProps>`
  margin-bottom: ${props => (props.hasError ? "5px" : "15px")};
  padding: 10px;
  width: 100%;

  background: #eeeeee;
  border: 1px solid ${props => (props.hasError ? "#ED4A4A" : "#e1e1e1")};
  border-radius: 5px;
  color: ${props => (props.hasError ? "#ED4A4A" : "inherit")};
  font-size: 1rem;

  ${props => (props.disabled ? "opacity: .5;" : "")}
`;

const BasicPrimaryButton = styled(PrimaryButton)`
  margin: 0;
`;

const DeleteButton = styled(SecondaryButton)`
  margin: 0;

  background: #d8443b;
`;

export {
  ErrorMessage,
  Input,
  TextArea,
  Checkbox,
  PrimaryButton,
  CondensedPrimaryButton,
  SecondaryButton,
  CondensedSecondaryButton,
  Select,
  BasicPrimaryButton,
  DeleteButton
};
