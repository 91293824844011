import moment from "moment";

import firebase from "../firebase";
import { getPackage } from "./packages";
import { getCustomer } from "./customers";

const db = firebase.firestore();
const Timestamp = firebase.firestore.Timestamp;

export interface Package {
  name: string;
  partySize: number;
}

export interface BookingModel {
  id: string;
  name: string;
  day: string;
  startTime: string;
  packages: Package[];
  customer: {
    id: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    email: string;
  };
}

const getBookings = async (fieldId: string): Promise<any[]> => {
  const querySnapshot = await db
    .collection("bookings")
    .where("field_id", "==", fieldId)
    .get();

  const bookings: any[] = [];

  const promises = querySnapshot.docs.map(async doc => {
    const data = doc.data();

    const packages = await getBookingsPackages(fieldId, doc.id);
    const customer = await getCustomer(fieldId, data.customer_id);

    bookings.push({
      id: doc.id,
      name: data.name,
      startTime: moment(data.start_timestamp.toDate()).format("h:mm a"),
      startTimeRaw: data.start_timestamp.toDate(),
      day: moment(data.start_timestamp.toDate()).format("dddd, MMMM Do"),
      packages,
      customer
    });
  });

  await Promise.all(promises);

  return bookings;
};

const getBookingsPackages = async (
  fieldId: string,
  bookingId?: string
): Promise<any[]> => {
  let querySnapshot;

  if (!!bookingId) {
    querySnapshot = await db
      .collection("bookings_packages")
      .where("field_id", "==", fieldId)
      .where("booking_id", "==", bookingId)
      .get();
  } else {
    querySnapshot = await db
      .collection("bookings_packages")
      .where("field_id", "==", fieldId)
      .get();
  }

  const packages: any[] = [];

  const promises = querySnapshot.docs.map(async (doc: any, i: number) => {
    const data = doc.data();

    const bookingPackage = {
      bookingId: data.booking_id,
      packageId: data.package_id,
      quantity: data.quantity
    };

    const { name } = await getPackage(bookingPackage.packageId);

    packages.push({
      id: bookingPackage.packageId,
      name: name,
      quantity: bookingPackage.quantity
    });
  });

  await Promise.all(promises);

  return packages;
};

const getBooking = async (
  fieldId: string,
  bookingId: string
): Promise<BookingModel> => {
  const bookingDoc = await db
    .collection("bookings")
    .doc(bookingId)
    .get();
  const data = bookingDoc.data();

  const packages = await getBookingsPackages(fieldId, bookingId);
  const customer: any = await getCustomer(fieldId, data.customer_id);

  return {
    id: bookingDoc.id,
    name: data.name,
    startTime: moment(data.start_timestamp.toDate()).format("h:mm a"),
    day: moment(data.start_timestamp.toDate()).format("dddd, MMMM Do"),
    packages,
    customer
  };
};

const addBooking = async (fieldId: string, booking: any) => {
  const newBooking = await db.collection("bookings").add({
    field_id: fieldId,
    name: booking.name,
    start_timestamp: booking.appointment,
    customer_id: booking.customer.id
  });

  const promises = booking.packages.map(async (selectedPackage: any) => {
    if (!selectedPackage.id) {
      return Promise.resolve();
    }

    await db.collection("bookings_packages").add({
      field_id: fieldId,
      booking_id: newBooking.id,
      package_id: selectedPackage.id,
      quantity: selectedPackage.quantity.toString()
    });
  });

  await Promise.all(promises);

  return newBooking;
};

const updateBooking = async (fieldId: string, booking: any) => {
  await db
    .collection("bookings")
    .doc(booking.id)
    .update({
      name: booking.name,
      start_timestamp: booking.appointment,
      customer_id: booking.customer.id
    });

  const bookingsPackages = await db
    .collection("bookings_packages")
    .where("field_id", "==", fieldId)
    .where("booking_id", "==", booking.id)
    .get();

  await bookingsPackages.forEach((doc: any) => {
    doc.ref.delete();
  });

  const promises = booking.packages.map(
    async (pkg: any) =>
      await db.collection("bookings_packages").add({
        field_id: fieldId,
        booking_id: booking.id,
        package_id: pkg.id,
        quantity: pkg.quantity
      })
  );

  return Promise.all(promises);
};

const deleteBooking = async (fieldId: string, bookingId: string) => {
  const bookingsPackages = await db
    .collection("bookings_packages")
    .where("field_id", "==", fieldId)
    .where("booking_id", "==", bookingId)
    .get();

  await bookingsPackages.forEach((doc: any) => {
    doc.ref.delete();
  });

  return await db
    .collection("bookings")
    .doc(bookingId)
    .delete();
};

const getBookingsByCustomerId = async (fieldId: string, customerId: string) => {
  const querySnapshot = await db
    .collection("bookings")
    .where("field_id", "==", fieldId)
    .where("customer_id", "==", customerId)
    .get();

  const bookings: any[] = [];

  const promises = querySnapshot.docs.map(async doc => {
    const data = doc.data();

    const packages = await getBookingsPackages(fieldId, doc.id);
    const customer = await getCustomer(fieldId, data.customer_id);

    bookings.push({
      id: doc.id,
      name: data.name,
      startTime: moment(data.start_timestamp.toDate()).format("h:mm a"),
      startTimeRaw: data.start_timestamp.toDate(),
      day: moment(data.start_timestamp.toDate()).format("dddd, MMMM Do"),
      packages,
      customer
    });
  });

  await Promise.all(promises);

  return bookings;
};

const customerHasBookings = async (fieldId: string, customerId: string) => {
  const bookings = await getBookingsByCustomerId(fieldId, customerId);

  return bookings.length;
};

export {
  getBookingsByCustomerId,
  customerHasBookings,
  getBooking,
  getBookings,
  addBooking,
  deleteBooking,
  updateBooking,
  Timestamp
};
