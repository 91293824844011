import { CondensedLogoLink, Link } from "./elements/links";
import { Panel } from "./widgets/panel";
import { FieldEdit } from "./fieldEdit";
import { CenteredContent } from "./widgets/content";
import { signOutUser } from "../services/users";
import React from "react";

const FieldSetup: React.FC<{ onSave: () => void }> = ({ onSave }) => (
  <div>
    <CondensedLogoLink />
    <Panel>
      <FieldEdit onSave={onSave} />
      <CenteredContent>
        {`or `}
        <Link to="#" onClick={signOutUser}>
          Log out
        </Link>
      </CenteredContent>
    </Panel>
  </div>
);

export { FieldSetup };
