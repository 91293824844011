import React, { useEffect, useState } from "react";
import { BasicP, CenteredWithPadding } from "./widgets/content";
import { H1, H4, H5, SolidH4 } from "./elements/headers";
import { Container } from "./widgets/page";
import { ContactInformation } from "./widgets/contactInformation";
import { ActionIcon } from "./widgets/icons";
import styled from "styled-components";
import firebase from "../firebase";
import { getAccount } from "../services/account";
import { Route, Switch } from "react-router";
import { AccountSettingsEdit } from "./accountSettingsEdit";
import { ButtonContainer } from "./widgets/buttonContainer";
import { BasicPrimaryButton } from "./elements/forms";

const ResetMessageContainer = styled.div`
  margin-top: 10px;
`;

const AccountSettingsIndex: React.FC<{
  history: any;
  match: any;
  name: string;
  email: string;
}> = ({ history, match, name, email }) => {
  const [resetRequested, setResetRequested] = useState(false);

  const requestPasswordReset = async () => {
    setResetRequested(true);

    const forgotPassword = firebase.functions().httpsCallable("forgotPassword");

    await forgotPassword({ email });
  };

  return (
    <div>
      <CenteredWithPadding>
        <H1>Account settings</H1>
      </CenteredWithPadding>
      <Container>
        <SolidH4>{name}</SolidH4>
        <H5>Contact information</H5>
        <ContactInformation>
          <H5>Email</H5>
          <span>{email}</span>
        </ContactInformation>
        <ButtonContainer>
          <BasicPrimaryButton
            disabled={resetRequested}
            onClick={() => requestPasswordReset()}
          >
            <ActionIcon className="fas fa-key" />
            Reset password
          </BasicPrimaryButton>
          <BasicPrimaryButton
            onClick={() => history.push(`${match.path}/edit-account-settings`)}
          >
            <ActionIcon className="fas fa-pencil-alt" />
            Edit
          </BasicPrimaryButton>
        </ButtonContainer>
        {resetRequested && (
          <ResetMessageContainer>
            <H4>Request complete</H4>
            <BasicP>
              {`A password reset link will be delivered to `}
              <strong>{email}</strong>.
            </BasicP>
          </ResetMessageContainer>
        )}
      </Container>
    </div>
  );
};

const AccountSettings: React.FC<{ match: any }> = ({ match }) => {
  const [account, setAccount] = useState(null);

  const getMyAccount = async () => {
    const account = getAccount();

    setAccount(account);
  };

  useEffect(() => {
    getMyAccount();
    // eslint-disable-next-line
  }, [match.isExact]);

  return (
    <Switch key={match.path}>
      <Route
        exact
        path={match.path}
        render={(props: any) => (
          <AccountSettingsIndex {...props} {...account} />
        )}
      />
      <Route
        path={`${match.path}/edit-account-settings`}
        render={(props: any) => <AccountSettingsEdit {...props} {...account} />}
      />
    </Switch>
  );
};

export { AccountSettings };
