import React from "react";
import { LogoLink } from "./elements/links";
import { Panel } from "./widgets/panel";
import { H1 } from "./elements/headers";
import { P } from "./widgets/content";

const Downtime = () => (
  <div>
    <LogoLink />
    <Panel>
      <H1>Maintenance</H1>
      <P>LGP is currently down for maintenance. We'll be back up shortly!</P>
    </Panel>
  </div>
);

export { Downtime };
