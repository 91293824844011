import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";

import Login from "./components/login";
import SignUp from "./components/signUp";
import ForgotPassword from "./components/forgotPassword";
import PasswordReset from "./components/passwordReset";
import firebase from "./firebase";
import { AccessibleRoutes } from "./accessibleRoutes";
import { pageTitleFormatter } from "./utils/formatters";
import { AccountSetup } from "./components/AccountSetup";
import { NON_PROD_LOG } from "./utils/loggers";
import { getUserDetails } from "./services/users";
import { FieldSetup } from "./components/fieldSetup";
import { Downtime } from "./components/downtime";
import { getIsAppDown } from "./services/downtime";

const AppStyled = styled.div`
  height: 100vh;

  color: #22252d;
  font-family: "Roboto", sans-serif;
`;

const App: React.FC = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const [isDown, setIsDown] = useState(null);
  const [userDetails, setUserDetails] = useState(null);

  const hasField = userDetails && userDetails.fieldId;
  const isOnboarded = hasField;

  const getIsOnboarded = async () => {
    const userDetails = await getUserDetails();

    setUserDetails(userDetails);
  };

  const getIsDown = async () => {
    const isDown = await getIsAppDown();

    setIsDown(isDown);
  };

  useEffect(() => {
    getIsDown();

    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user && user.emailVerified && !isAuthenticated) {
        setIsAuthenticated(true);

        await getIsOnboarded();

        NON_PROD_LOG("Authenticated");
      } else {
        setIsAuthenticated(false);

        NON_PROD_LOG("Unauthenticated");
      }
    });

    return unsubscribe;
    // eslint-disable-next-line
  }, []);

  const onSaveField = async (history: any) => {
    await getIsOnboarded();

    history.push("/dashboard");
  };

  return (
    <AppStyled>
      <Router>
        {isDown === false && isAuthenticated && (
          <Switch>
            <Redirect path="/login" to="/dashboard" />
            <Redirect path="/sign-up" to="/dashboard" />
            <Redirect path="/forgot-password" to="/dashboard" />

            <Route
              path="/password-reset"
              render={(props: any) => {
                document.title = pageTitleFormatter("Password reset");

                return <PasswordReset {...props} />;
              }}
            />

            {isOnboarded && (
              <>
                <Redirect path="/account-setup" to="/dashboard" />
                <Redirect path="/field-setup" to="/dashboard" />

                <Route
                  path="*"
                  render={(props: any) => (
                    <AccessibleRoutes {...props} userDetails={userDetails} />
                  )}
                />
              </>
            )}

            {isOnboarded === false && (
              <>
                <Route
                  path="/account-setup"
                  render={(props: any) => {
                    document.title = pageTitleFormatter("Account setup");

                    return <AccountSetup {...props} />;
                  }}
                />
                <Route
                  path="/field-setup"
                  render={(props: any) => {
                    document.title = pageTitleFormatter("Field setup");

                    return (
                      <FieldSetup onSave={() => onSaveField(props.history)} />
                    );
                  }}
                />

                <Redirect to="/account-setup" />
              </>
            )}
          </Switch>
        )}
        {isDown === false && isAuthenticated === false && (
          <Switch>
            <Route
              path="/login"
              render={(props: any) => {
                document.title = pageTitleFormatter("Login");

                return <Login {...props} />;
              }}
            />
            <Route
              path="/sign-up"
              render={(props: any) => {
                document.title = pageTitleFormatter("Sign up");

                return <SignUp {...props} />;
              }}
            />
            <Route
              path="/forgot-password"
              render={(props: any) => {
                document.title = pageTitleFormatter("Forgot password");

                return <ForgotPassword {...props} />;
              }}
            />
            <Route
              path="/password-reset"
              render={(props: any) => {
                document.title = pageTitleFormatter("Password reset");

                return <PasswordReset {...props} />;
              }}
            />

            <Redirect to="/login" />
          </Switch>
        )}
        {isDown && (
          <>
            <Downtime />

            <Redirect to="/" />
          </>
        )}
      </Router>
    </AppStyled>
  );
};

export default App;
