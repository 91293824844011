import firebase from "../firebase";

const db = firebase.firestore();

const getIsAppDown = async () => {
  const querySnapshot = await db.collection("downtime").get();

  return querySnapshot.docs.map(doc => {
    const data = doc.data();

    return data.is_down;
  })[0];
};

export { getIsAppDown };
