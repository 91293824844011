const isValidEmail = (email: string): boolean => {
  const emailRegex = /^[-!#$%&'*+/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&'*+/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

  const maxEmailLength = 254;
  const maxEmailNameLength = 64;
  const maxEmailDomainPartsLength = 63;

  const emailIsEmpty = !email;
  const emailExceedsMaxLength = email.length > maxEmailLength;

  if (emailIsEmpty || emailExceedsMaxLength) {
    return false;
  }

  const emailIsValid = emailRegex.test(email);

  if (!emailIsValid) {
    return false;
  }

  const parts = email.split("@");
  const emailNameExceedsMaxLength = parts[0].length > maxEmailNameLength;

  if (emailNameExceedsMaxLength) {
    return false;
  }

  const domainParts = parts[1].split(".");
  const emailDomainPartExceedsMaxLength = domainParts.some(
    part => part.length > maxEmailDomainPartsLength
  );

  if (emailDomainPartExceedsMaxLength) {
    return false;
  }

  return true;
};

export { isValidEmail };
