import styled from "styled-components";

const PriceContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;

const PerContainer = styled.div`
  display: flex;
  align-items: center;
`;

const PerCheckboxContainer = styled.span`
  margin-right: 5px;
`;

export { PriceContainer, PerCheckboxContainer, PerContainer };
