import React, { useEffect, useState } from "react";
import { CenteredWithPadding, P } from "./widgets/content";
import { H1, H4 } from "./elements/headers";
import { Container } from "./widgets/page";
import {
  Checkbox,
  CondensedPrimaryButton,
  PrimaryButton,
  Select,
  TextArea
} from "./elements/forms";
import styled from "styled-components";
import { InputControl } from "./elements/inputControl";
import {
  addWeeklyPackage,
  getPackage,
  updateWeeklyPackage
} from "../services/packages";
import {
  PerCheckboxContainer,
  PerContainer,
  PriceContainer
} from "./widgets/pricePerContainer";

const DurationOptions = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 10px;
`;

const DaysContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 15px;
`;

const DayContainer = styled.div<{ selected: boolean }>`
  padding: 5px 2px;

  background: ${props => (props.selected ? "#4498d8" : "#eeeeee")};
  border-radius: 5px;
  ${props => (props.selected ? "color: #ffffff" : "")}
  text-align: center;
`;

const PartyAmountContainer = styled.div<{ isRange: boolean }>`
  display: grid;
  grid-template-columns: ${props => (props.isRange ? "1fr auto 1fr" : "1fr")};
  grid-column-gap: 5px;
`;

const ToContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`;

const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const PackagesAdd: React.FC<{
  title: string;
  match: any;
  history: any;
  userDetails: any;
}> = ({ match, title, history, userDetails }) => {
  const [packageId, setPackageId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [paintballQuantity, setPaintballQuantity] = useState("");
  const [durationUnit, setDurationUnit] = useState("");
  const [durationQuantity, setDurationQuantity] = useState("");
  const [selectedDays, setSelectedDays] = useState([]);
  const [equipment, setEquipment] = useState([]);
  const [partySize, setPartySize] = useState("");
  const [leftPartyAmount, setLeftPartyAmount] = useState("");
  const [rightPartyAmount, setRightPartyAmount] = useState("");
  const [price, setPrice] = useState("");
  const [perHourPriceEnabled, setPerHourPriceEnabled] = useState(true);
  const [perHourPrice, setPerHourPrice] = useState("");
  const [perPlayerPrice, setPerPlayerPrice] = useState("");
  const [perPlayerPriceEnabled, setPerPlayerPriceEnabled] = useState(true);

  const getPackageToEdit = async () => {
    const pkg = await getPackage(match.params.packageId);

    setPackageId(pkg.id);
    setName(pkg.name);
    setDescription(pkg.description);
    setPaintballQuantity(pkg.paintballQuantity);
    setEquipment(pkg.equipment);
    setSelectedDays(pkg.daysOfTheWeek);
    setDurationUnit(pkg.durationUnit);
    setDurationQuantity(pkg.durationQuantity);
    setPartySize(pkg.partySize);
    setLeftPartyAmount(pkg.leftPartyAmount);
    setRightPartyAmount(pkg.rightPartyAmount);
    setPrice(pkg.price);
    setPerHourPrice(pkg.perHourPrice);
    setPerPlayerPrice(pkg.perPlayerPrice);
  };

  useEffect(() => {
    if (match.params.packageId) {
      getPackageToEdit();
    }

    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setLeftPartyAmount("");
    setRightPartyAmount("");
  }, [partySize]);

  useEffect(() => {
    setPerHourPrice("");
  }, [perHourPriceEnabled]);

  useEffect(() => {
    setPerPlayerPrice("");
  }, [perPlayerPriceEnabled]);

  const toggleDay = (day: string) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(selectedDays.filter(d => day !== d));

      return;
    }

    setSelectedDays([...selectedDays, day]);
  };

  const updateEquipmentItem = (index: number, input: string) => {
    const updatedEquipment = equipment.map((eq, i) => {
      if (index === i) {
        return input;
      }

      return eq;
    });

    setEquipment(updatedEquipment);
  };

  const savePackage = async () => {
    if (packageId) {
      await updateWeeklyPackage({
        id: packageId,
        name,
        description,
        paintballQuantity,
        equipment,
        daysOfTheWeek: selectedDays,
        durationUnit,
        durationQuantity,
        partySize,
        leftPartyAmount,
        rightPartyAmount,
        price,
        perHourPrice,
        perPlayerPrice
      });
    } else {
      await addWeeklyPackage(userDetails.fieldId, {
        name,
        description,
        paintballQuantity,
        equipment,
        daysOfTheWeek: selectedDays,
        durationUnit,
        durationQuantity,
        partySize,
        leftPartyAmount,
        rightPartyAmount,
        price,
        perHourPrice,
        perPlayerPrice
      });
    }

    history.push("/packages");
  };

  const additionalPlayerPricingDisabled =
    partySize === "any" || partySize === "one player";
  const additionalHourPricingDisabled = durationUnit === "all day";
  const showPartyAmounts = partySize !== "any";
  const showSecondPartyAmount = partySize === "range";

  return (
    <>
      <CenteredWithPadding>
        <H1>{title}</H1>
      </CenteredWithPadding>
      <Container>
        <div>
          <H4>Details</H4>
          <InputControl
            type="text"
            placeholder="Package name"
            value={name}
            onChange={(e: any) => setName(e.target.value)}
          />
          <P>
            Describe your package and provide any additional information to
            potential customers.
          </P>
          <TextArea
            onChange={(e: any) => setDescription(e.target.value)}
            placeholder="Package description"
            rows={5}
            value={description}
          />
          <H4>Paintballs</H4>
          <P>How many paintballs are included with this package?</P>
          <InputControl
            type="number"
            placeholder="Paintball quantity"
            value={paintballQuantity}
            onChange={(e: any) => setPaintballQuantity(e.target.value)}
          />
          <H4>Equipment</H4>
          <P>Does this package include any equipment?</P>
          {equipment.map((eq, i) => (
            <React.Fragment key={i}>
              <InputControl
                type="text"
                value={eq}
                placeholder="Equipment description"
                onChange={(e: any) => updateEquipmentItem(i, e.target.value)}
              />
            </React.Fragment>
          ))}
          <CondensedPrimaryButton
            onClick={() => setEquipment([...equipment, ""])}
          >
            + add an equipment item
          </CondensedPrimaryButton>
          <H4>Days of the week</H4>
          <P>Which days will this package be available?</P>
          <DaysContainer>
            {days.map(day => (
              <DayContainer
                key={day}
                onClick={() => toggleDay(day)}
                selected={selectedDays.includes(day)}
              >
                {day}
              </DayContainer>
            ))}
          </DaysContainer>
          <H4>Duration</H4>
          <P>
            How much time will players have to play at your field with this
            package?
          </P>
          <DurationOptions>
            <Select
              value={durationUnit}
              onChange={(e: any) => setDurationUnit(e.target.value)}
            >
              <option value="" disabled>
                Select an option
              </option>
              <option value="hours">Hours</option>
              <option value="all day">All day</option>
            </Select>
            <InputControl
              disabled={durationUnit === "all day"}
              type="number"
              placeholder="Quantity"
              onChange={(e: any) => setDurationQuantity(e.target.value)}
              value={durationQuantity}
            />
          </DurationOptions>
          <H4>Party size</H4>
          <P>
            How many players are permitted to play at your field with this
            package?
          </P>
          <Select
            value={partySize}
            onChange={(e: any) => setPartySize(e.target.value)}
          >
            <option value="" disabled>
              Select an option
            </option>
            <option value="specific">Specific amount</option>
            <option value="less than">Less than an amount</option>
            <option value="range">Within a range</option>
            <option value="more than">More than an amount</option>
            <option value="any">Any amount</option>
          </Select>
          {showPartyAmounts && (
            <PartyAmountContainer isRange={partySize === "range"}>
              <InputControl
                value={leftPartyAmount}
                onChange={(e: any) => setLeftPartyAmount(e.target.value)}
                type="number"
                placeholder="Amount"
              />
              {showSecondPartyAmount && (
                <>
                  <ToContainer>to</ToContainer>
                  <InputControl
                    value={rightPartyAmount}
                    onChange={(e: any) => setRightPartyAmount(e.target.value)}
                    type="number"
                    placeholder="Amount"
                  />
                </>
              )}
            </PartyAmountContainer>
          )}
          <H4>Pricing</H4>
          <P>How much does this package cost?</P>
          <InputControl
            type="number"
            placeholder="Price"
            value={price}
            onChange={(e: any) => setPrice(e.target.value)}
          />
          <P>
            Can additional hours or players be added to this package? If so, how
            much will each cost?
          </P>
          <PriceContainer>
            <PerContainer>
              <P>
                <PerCheckboxContainer>
                  <Checkbox
                    disabled={additionalHourPricingDisabled}
                    checked={perHourPriceEnabled}
                    onChange={() =>
                      setPerHourPriceEnabled(!perHourPriceEnabled)
                    }
                  />
                </PerCheckboxContainer>
                Per additional <strong>hour</strong>
              </P>
            </PerContainer>
            <InputControl
              disabled={additionalHourPricingDisabled || !perHourPriceEnabled}
              type="number"
              placeholder="Price"
              onChange={(e: any) => setPerHourPrice(e.target.value)}
              value={perHourPrice}
            />
          </PriceContainer>
          <PriceContainer>
            <PerContainer>
              <P>
                <PerCheckboxContainer>
                  <Checkbox
                    disabled={additionalPlayerPricingDisabled}
                    checked={perPlayerPriceEnabled}
                    onChange={() =>
                      setPerPlayerPriceEnabled(!perPlayerPriceEnabled)
                    }
                  />
                </PerCheckboxContainer>
                Per additional <strong>player</strong>
              </P>
            </PerContainer>
            <InputControl
              disabled={
                additionalPlayerPricingDisabled || !perPlayerPriceEnabled
              }
              type="number"
              placeholder="Price"
              onChange={(e: any) => setPerPlayerPrice(e.target.value)}
              value={perPlayerPrice}
            />
          </PriceContainer>
          <PrimaryButton onClick={savePackage}>Save</PrimaryButton>
        </div>
      </Container>
    </>
  );
};

export { PackagesAdd };
