import React, { useEffect, useState } from "react";
import { CenteredWithPadding, P } from "./widgets/content";
import { BasicH5, H1 } from "./elements/headers";
import { Container } from "./widgets/page";
import styled from "styled-components";
import { SelectableProps } from "../interfaces";
import { Switch, Route } from "react-router-dom";
import { Actions } from "./widgets/actions";
import { EquipmentAdd } from "./equipmentAdd";
import { deleteEquipment, getEquipment } from "../services/equipment";
import { EmptyState } from "./widgets/emptyState";
import { Image } from "./elements/image";
import { ImageContainer } from "./widgets/imageContainer";

const EquipmentCard = styled.div<SelectableProps>`
  padding: 10px;
  margin-bottom: 10px;

  background: ${props => (props.isSelected ? "#F5EDB7" : "#eeeeee")};
  border-radius: 5px;

  &:last-child {
    margin: 0;
  }
`;

const EquipmentSummary = styled.div`
  display: grid;
  grid-template-columns: 1fr 7fr;
  column-gap: 10px;
`;

const EquipmentDetail = styled.div`
  margin-top: 10px;
  padding-top: 10px;
  border-top: 3px dotted #22252d;
`;

const DetailsTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-gap: 10px;
`;

const EquipmentImage = styled(Image)`
  border-radius: 5px;
  max-height: 25px;

  &:after {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

const ImageWrapper: React.FC<{ url: string }> = ({ url }) => {
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    setImageError(false);
  }, [url]);

  if (!url || imageError) {
    return <ImageContainer />;
  }

  return (
    <EquipmentImage
      src={url}
      onClick={() => window.open(url, "_blank")}
      onError={() => setImageError(true)}
    />
  );
};

const EquipmentIndex: React.FC<{
  initialized: boolean;
  equipment: any[];
  clickEquipment: (id: string) => void;
  selectedEquipmentId: string;
  clickAdd: () => void;
  clickEdit: (id: string) => void;
  clickDelete: (id: string) => void;
}> = ({
  initialized,
  equipment,
  clickEquipment,
  selectedEquipmentId,
  clickAdd,
  clickEdit,
  clickDelete
}) => (
  <React.Fragment>
    <CenteredWithPadding>
      <H1>Equipment</H1>
    </CenteredWithPadding>
    <Container>
      {!equipment.length && initialized && (
        <EmptyState
          text="You haven't added any equipment yet"
          buttonText="Add equipment"
          icon="fas fa-tags"
          onClick={clickAdd}
        />
      )}
      {equipment.map(item => (
        <React.Fragment key={item.id}>
          <EquipmentCard
            onClick={() => clickEquipment(item.id)}
            isSelected={item.id === selectedEquipmentId}
          >
            <EquipmentSummary>
              <ImageWrapper url={item.fileURL} />
              <div>{item.name}</div>
            </EquipmentSummary>
            {selectedEquipmentId === item.id && (
              <EquipmentDetail>
                <P>{item.description}</P>
                <DetailsTable>
                  <BasicH5>Quantity</BasicH5>
                  <div>{item.quantity}</div>
                  {item.price && (
                    <>
                      <BasicH5>Price</BasicH5>
                      <div>${item.price}</div>
                    </>
                  )}
                </DetailsTable>
                <Actions
                  clickEdit={() => clickEdit(item.id)}
                  clickDelete={() => clickDelete(item.id)}
                />
              </EquipmentDetail>
            )}
          </EquipmentCard>
        </React.Fragment>
      ))}
    </Container>
  </React.Fragment>
);

const Equipment: React.FC<{
  match: any;
  history: any;
  setIsLoading: (isLoading: boolean) => void;
  userDetails: any;
}> = ({ match, history, setIsLoading, userDetails }) => {
  const [initialized, setInitialized] = useState(false);
  const [selectedEquipmentId, setSelectedEquipmentId] = useState(null);
  const [equipment, setEquipment] = useState([]);

  const getAllEquipment = async () => {
    setIsLoading(true);
    const allEquipment = await getEquipment(userDetails.fieldId);
    setEquipment(allEquipment);
    setIsLoading(false);
  };

  useEffect(() => {
    getAllEquipment();

    setInitialized(true);
    // eslint-disable-next-line
  }, [match.isExact]);

  const clickEquipment = (equipmentId: string) => {
    if (selectedEquipmentId === equipmentId) {
      setSelectedEquipmentId(null);

      return;
    }

    setSelectedEquipmentId(equipmentId);
  };

  const clickAdd = () => history.push(`${match.path}/add-equipment/`);

  const clickEdit = (id: string) =>
    history.push(`${match.path}/edit-equipment/${id}`);

  const clickDelete = async (equipmentId: string) => {
    setIsLoading(true);
    await deleteEquipment(equipmentId);
    await getAllEquipment();

    setSelectedEquipmentId(null);
    setIsLoading(false);
  };

  return (
    <Switch>
      <Route
        exact
        path={match.path}
        render={() => (
          <EquipmentIndex
            initialized={initialized}
            equipment={equipment}
            clickEquipment={clickEquipment}
            selectedEquipmentId={selectedEquipmentId}
            clickAdd={clickAdd}
            clickEdit={clickEdit}
            clickDelete={clickDelete}
          />
        )}
      />
      <Route
        path={`${match.path}/add-equipment`}
        render={(props: any) => (
          <EquipmentAdd
            {...props}
            title="Add equipment"
            setIsLoading={setIsLoading}
            userDetails={userDetails}
          />
        )}
      />
      <Route
        path={`${match.path}/edit-equipment/:equipmentId`}
        render={(props: any) => (
          <EquipmentAdd
            {...props}
            title="Edit equipment"
            setIsLoading={setIsLoading}
            userDetails={userDetails}
          />
        )}
      />
    </Switch>
  );
};

export { Equipment };
