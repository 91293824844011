import { CenteredWithPadding } from "./widgets/content";
import { H1, H4 } from "./elements/headers";
import { Container } from "./widgets/page";
import React, { useState } from "react";
import { InputControl } from "./elements/inputControl";
import { PrimaryButton } from "./elements/forms";
import firebase from "../firebase";

const AccountSettingsEdit: React.FC<{
  history: any;
  name: string;
  email: string;
}> = ({ history, name, email }) => {
  const [newEmail, setNewEmail] = useState(email || "");
  const [newName, setNewName] = useState(name || "");

  const saveAccount = async () => {
    await firebase.auth().currentUser.updateEmail(newEmail);
    await firebase.auth().currentUser.updateProfile({
      displayName: newName
    });

    history.push("/account-settings");
  };

  return (
    <div>
      <CenteredWithPadding>
        <H1>Edit account settings</H1>
      </CenteredWithPadding>
      <Container>
        <H4>Contact information</H4>
        <InputControl
          type="text"
          placeholder="Name"
          value={newName}
          onChange={(e: any) => setNewName(e.target.value)}
        />
        <InputControl
          type="text"
          placeholder="Email"
          value={newEmail}
          onChange={(e: any) => setNewEmail(e.target.value)}
        />
        <PrimaryButton onClick={saveAccount}>Save</PrimaryButton>
      </Container>
    </div>
  );
};

export { AccountSettingsEdit };
