import React from "react";
import styled from "styled-components";
import Snackbar, { SnackbarProps } from "@material-ui/core/Snackbar";

const StyledSnackbar = styled(Snackbar)`
  .MuiSnackbarContent-root {
    background: #ed4a4a;
  }
` as React.ComponentType<SnackbarProps>;

export { StyledSnackbar as Snackbar };
