import firebase from "../firebase";

const db = firebase.firestore();

const getPackages = async (fieldId: string) => {
  const querySnapshot = await db
    .collection("packages")
    .where("field_id", "==", fieldId)
    .get();

  return querySnapshot.docs.map(doc => {
    const data = doc.data();

    return {
      id: doc.id,
      cadence: data.cadence,
      name: data.name,
      description: data.description,
      paintballQuantity: data.paintball_quantity,
      equipment: data.equipment,
      daysOfTheWeek: data.days_of_week,
      durationUnit: data.duration_unit,
      durationQuantity: data.duration_quantity,
      partySize: data.party_size,
      leftPartyAmount: data.left_party_amount,
      rightPartyAmount: data.right_party_amount,
      price: data.price,
      perHourPrice: data.per_hour_price,
      perPlayerPrice: data.per_player_price
    };
  });
};

const getPackage = async (packageId: string) => {
  const doc = await db
    .collection("packages")
    .doc(packageId)
    .get();
  const data = doc.data();

  return {
    id: doc.id,
    name: data.name,
    description: data.description,
    paintballQuantity: data.paintball_quantity,
    equipment: data.equipment,
    daysOfTheWeek: data.days_of_week,
    durationUnit: data.duration_unit,
    durationQuantity: data.duration_quantity,
    partySize: data.party_size,
    leftPartyAmount: data.left_party_amount,
    rightPartyAmount: data.right_party_amount,
    price: data.price,
    perHourPrice: data.per_hour_price,
    perPlayerPrice: data.per_player_price
  };
};

const addWeeklyPackage = async (fieldId: string, pkg: any) =>
  await db.collection("packages").add({
    field_id: fieldId,
    cadence: "weekly",
    name: pkg.name,
    description: pkg.description,
    paintball_quantity: pkg.paintballQuantity,
    equipment: pkg.equipment,
    days_of_week: pkg.daysOfTheWeek,
    duration_unit: pkg.durationUnit,
    duration_quantity: pkg.durationQuantity,
    party_size: pkg.partySize,
    left_party_amount: pkg.leftPartyAmount,
    right_party_amount: pkg.rightPartyAmount,
    price: pkg.price,
    per_hour_price: pkg.perHourPrice,
    per_player_price: pkg.perPlayerPrice
  });

const updateWeeklyPackage = async (pkg: any) => {
  return await db
    .collection("packages")
    .doc(pkg.id)
    .update({
      name: pkg.name,
      description: pkg.description,
      paintball_quantity: pkg.paintballQuantity,
      equipment: pkg.equipment,
      days_of_week: pkg.daysOfTheWeek,
      duration_unit: pkg.durationUnit,
      duration_quantity: pkg.durationQuantity,
      party_size: pkg.partySize,
      left_party_amount: pkg.leftPartyAmount,
      right_party_amount: pkg.rightPartyAmount,
      price: pkg.price,
      per_hour_price: pkg.perHourPrice,
      per_player_price: pkg.perPlayerPrice
    });
};

const deletePackage = async (packageId: string) => {
  return await db
    .collection("packages")
    .doc(packageId)
    .delete();
};

export {
  getPackage,
  getPackages,
  addWeeklyPackage,
  updateWeeklyPackage,
  deletePackage
};
