import styled from "styled-components";

const H1 = styled.h1`
  margin: 0 0 10px 0;

  font-family: "Roboto", sans-serif;
  font-size: 2rem;
`;

const H2 = styled.h2`
  margin: 0 0 10px 0;

  font-family: "Roboto", sans-serif;
  font-size: 1.75rem;
`;

const H3 = styled.h3`
  margin: 0 0 10px 0;

  font-family: "Roboto", sans-serif;
  font-size: 1.5rem;
`;

const H4 = styled.h4`
  margin: 0 0 10px 0;

  font-family: "Roboto", sans-serif;
  font-size: 1.25rem;
`;

const BasicH4 = styled(H4)`
  margin: 0;

  line-height: 1;
`;

const SolidH4 = styled(H4)`
  padding: 5px 10px;

  background: #22252d;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
`;

const H5 = styled.h5`
  margin: 0 0 10px 0;

  font-family: "Roboto", sans-serif;
  font-size: 1rem;
  font-weight: bold;
`;

const BasicH5 = styled(H5)`
  margin: 0;
`;

const SolidH5 = styled(H5)`
  padding: 5px 10px;

  background: #22252d;
  border-radius: 5px;
  color: #ffffff;
  text-align: center;
`;

export { H1, H2, H3, H4, H5, SolidH4, BasicH4, SolidH5, BasicH5 };
