import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Route, Switch } from "react-router-dom";

import { H1, SolidH4, H5 } from "./elements/headers";
import { CenteredWithPadding, P } from "./widgets/content";
import { Actions } from "./widgets/actions";
import { Anchor } from "./elements/links";
import { BookingModel, deleteBooking, getBookings } from "../services/bookings";
import { BookingsAdd } from "./bookingsAdd";
import { SelectableProps } from "../interfaces";
import { Container } from "./widgets/page";
import { List, ListItem } from "./elements/lists";
import { ContactInformation } from "./widgets/contactInformation";
import { EmptyState } from "./widgets/emptyState";
import moment from "moment";
import { ActionIcon } from "./widgets/icons";
import { InputControl } from "./elements/inputControl";

const TimeSlot = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin: 0;
  }
`;

const Time = styled.span`
  display: inline-block;
  padding: 5px;
  margin-bottom: 10px;

  border: 1px solid #22252d;
  border-radius: 15px;
  font-size: 0.8rem;
`;

const Booking = styled.div<SelectableProps>`
  margin-bottom: 10px;
  padding: 10px;

  background: ${props => (props.isSelected ? "#F5EDB7" : "#eeeeee")};
  border-radius: 5px;

  &:last-child {
    margin: 0;
  }
`;

const BookingSummary = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BookingDetailContainer = styled.div<SelectableProps>`
  display: ${props => (props.isSelected ? "block" : "none")};
  margin-top: 10px;
  padding-top: 10px;

  border-top: 3px dotted #22252d;
`;

const Players = styled.div`
  font-weight: bold;
`;

const Day = styled.div`
  margin-bottom: 20px;
`;

const Packages = styled.div`
  margin-top: 10px;
`;

const PackageItem = styled(ListItem)`
  display: flex;
  justify-content: space-between;
`;

const BookingName = styled.span`
  font-weight: bold;
`;

const FilterContainer = styled.div<{ isDisabled: boolean }>`
  margin-bottom: 10px;

  text-align: right;

  ${props => (props.isDisabled ? "opacity: .5;" : "")}
`;

const FilterContent = styled.div`
  margin-bottom: 15px;
  padding: 15px;

  border: 3px dotted #22252d;
  border-radius: 5px;
`;

const DateFilterContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 10px;
`;

const DateFilterLabel = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 15px;
`;

const formatBookingsByDays = (
  bookings: any[],
  filterDateFrom: string,
  filterDateTo: string
) => {
  const days: any = {};

  bookings
    .sort((a: any, b: any) =>
      Number(new Date(a.startTimeRaw)) > Number(new Date(b.startTimeRaw))
        ? 1
        : -1
    )
    .filter((booking: any) => {
      const isAfterFromDate = moment(booking.startTimeRaw).isSameOrAfter(
        moment(filterDateFrom),
        "day"
      );
      const isBeforeToDate = moment(booking.startTimeRaw).isSameOrBefore(
        moment(filterDateTo),
        "day"
      );

      return !filterDateTo
        ? isAfterFromDate
        : isAfterFromDate && isBeforeToDate;
    })
    .forEach((booking: any) => {
      if (days[booking.day]) {
        // day exists
        let dayAndTime = days[booking.day][booking.startTime];

        if (dayAndTime) {
          // time slot exists, add a booking
          dayAndTime.push(booking);
        } else {
          // create time slot
          days[booking.day][booking.startTime] = [booking];
        }
      } else {
        // create day
        days[booking.day] = {
          [booking.startTime]: [booking]
        };
      }
    });

  return Object.keys(days).map(day => ({
    day,
    timesPerDay: Object.keys(days[day]).map(time => ({
      time,
      bookingsPerTime: days[day][time]
    }))
  }));
};

const getTotalPartySize = (packages: any) =>
  packages
    .map((p: any) => p.quantity)
    .reduce((a: any, b: any) => parseInt(a) + parseInt(b), 0);

const Filters: React.FC<{
  isDisabled: boolean;
  toggleFilters: any;
  filtersIsOpen: boolean;
  filterDateFrom: string;
  setFilterDateFrom: (filterDateFrom: any) => void;
  filterDateTo: string;
  setFilterDateTo: (filterDateFrom: any) => void;
}> = ({
  isDisabled,
  toggleFilters,
  filtersIsOpen,
  filterDateFrom,
  setFilterDateFrom,
  filterDateTo,
  setFilterDateTo
}) => (
  <>
    <FilterContainer isDisabled={isDisabled}>
      <div onClick={toggleFilters}>
        <ActionIcon className="fas fa-sliders-h" />
        <strong>{!filtersIsOpen ? "Filter bookings" : "Hide filters"}</strong>
      </div>
    </FilterContainer>
    {filtersIsOpen && (
      <FilterContent>
        <H5>Date range</H5>
        <DateFilterContainer>
          <DateFilterLabel>From</DateFilterLabel>
          <InputControl
            type="date"
            value={filterDateFrom}
            onChange={(e: any) => setFilterDateFrom(e.target.value)}
          />
          <DateFilterLabel>To</DateFilterLabel>
          <InputControl
            type="date"
            value={filterDateTo}
            onChange={(e: any) => setFilterDateTo(e.target.value)}
          />
        </DateFilterContainer>
      </FilterContent>
    )}
  </>
);

const BookingsIndex: React.FunctionComponent<{
  bookingsByDays: any[];
  selectedBookingId: string;
  clickBooking: (id: string) => void;
  clickAdd: () => void;
  clickEdit: (bookingId: string) => void;
  clickDelete: (bookingId: string) => void;
  initialized: boolean;
  filterDateFrom: string;
  setFilterDateFrom: (filterDateFrom: any) => void;
  filterDateTo: string;
  setFilterDateTo: (filterDateFrom: any) => void;
}> = ({
  bookingsByDays,
  selectedBookingId,
  clickBooking,
  initialized,
  clickAdd,
  clickEdit,
  clickDelete,
  filterDateFrom,
  setFilterDateFrom,
  filterDateTo,
  setFilterDateTo
}) => {
  const [filtersIsOpen, setFiltersIsOpen] = useState(false);

  return (
    <React.Fragment>
      <CenteredWithPadding>
        <H1>Bookings</H1>
      </CenteredWithPadding>
      <Container>
        {initialized && (
          <Filters
            isDisabled={!bookingsByDays.length}
            toggleFilters={() =>
              !!bookingsByDays.length && setFiltersIsOpen(!filtersIsOpen)
            }
            filtersIsOpen={filtersIsOpen}
            filterDateFrom={filterDateFrom}
            setFilterDateFrom={setFilterDateFrom}
            filterDateTo={filterDateTo}
            setFilterDateTo={setFilterDateTo}
          />
        )}
        {!bookingsByDays.length && initialized && (
          <EmptyState
            text="You haven't added any bookings yet"
            buttonText="Add a booking"
            icon="fas fa-clipboard"
            onClick={clickAdd}
          />
        )}
        {bookingsByDays.map(({ day, timesPerDay }, i) => (
          <Day key={i}>
            <SolidH4>{day}</SolidH4>
            <div>
              {timesPerDay.map((time: any, i: any) => (
                <TimeSlot key={i}>
                  <Time>{time.time}</Time>
                  {time.bookingsPerTime.map((booking: any) => (
                    <Booking
                      key={booking.id}
                      isSelected={booking.id === selectedBookingId}
                      onClick={() => clickBooking(booking.id)}
                    >
                      <BookingSummary>
                        <BookingName>{booking.name}</BookingName>
                        <Players>
                          {`${getTotalPartySize(booking.packages)} `}
                          <ActionIcon className="fas fa-users fa-sm" />
                        </Players>
                      </BookingSummary>
                      <BookingDetail
                        isSelected={booking.id === selectedBookingId}
                        booking={booking}
                        clickEdit={clickEdit}
                        clickDelete={clickDelete}
                      />
                    </Booking>
                  ))}
                </TimeSlot>
              ))}
            </div>
          </Day>
        ))}
      </Container>
    </React.Fragment>
  );
};

const BookingDetail: React.FunctionComponent<{
  isSelected: boolean;
  booking: BookingModel;
  clickEdit: (bookingId: string) => void;
  clickDelete: (bookingId: string) => void;
}> = ({ isSelected, booking, clickEdit, clickDelete }) => (
  <BookingDetailContainer isSelected={isSelected}>
    <ContactInformation>
      <H5>Name</H5>
      <span>{`${booking.customer.firstName} ${booking.customer.lastName}`}</span>
      <H5>Phone</H5>
      <Anchor
        href={`tel:${booking.customer.phoneNumber}`}
        onClick={e => e.stopPropagation()}
      >
        {booking.customer.phoneNumber}
      </Anchor>
      <H5>Email</H5>
      <Anchor
        href={`mailto:${booking.customer.email}`}
        onClick={e => e.stopPropagation()}
      >
        {booking.customer.email}
      </Anchor>
    </ContactInformation>
    <Packages>
      <H5>Packages</H5>
      {!booking.packages.length && <P>No packages selected</P>}
      <List>
        {booking.packages.map((p: any) => (
          <PackageItem key={p.name}>
            <div>{p.name}</div>
            <strong>{`${p.quantity} #`}</strong>
          </PackageItem>
        ))}
      </List>
    </Packages>
    <Actions
      clickEdit={() => clickEdit(booking.id)}
      clickDelete={() => clickDelete(booking.id)}
    />
  </BookingDetailContainer>
);

const Bookings: React.FunctionComponent<{
  history: any;
  match: any;
  setIsLoading: (isLoading: boolean) => void;
  userDetails: any;
}> = ({ history, match, setIsLoading, userDetails }) => {
  const [bookingsByDays, setBookingsByDays] = useState([]);
  const [selectedBookingId, setSelectedBookingId] = useState(null);
  const [initialized, setInitialized] = useState(false);
  const [filterDateFrom, setFilterDateFrom] = useState(
    moment().format("YYYY-MM-DD")
  );
  const [filterDateTo, setFilterDateTo] = useState("");

  const getBookingsByDays = async () => {
    setIsLoading(true);
    const bookings = await getBookings(userDetails.fieldId);
    setIsLoading(false);
    setInitialized(true);

    const formattedBookings = formatBookingsByDays(
      bookings,
      filterDateFrom,
      filterDateTo
    );

    setBookingsByDays(formattedBookings);
  };

  useEffect(() => {
    getBookingsByDays();
    // eslint-disable-next-line
  }, [match.isExact, filterDateFrom, filterDateTo]);

  const clickBooking = (bookingId: string) => {
    if (bookingId === selectedBookingId) {
      setSelectedBookingId(null);

      return;
    }

    setSelectedBookingId(bookingId);
  };

  const clickAdd = () => history.push("/bookings/add-booking");

  const clickEdit = (bookingId: string) => {
    history.push(`/bookings/edit-booking/${bookingId}`);
  };

  const clickDelete = async (bookingId: string) => {
    await deleteBooking(userDetails.fieldId, bookingId);
    await getBookingsByDays();

    setSelectedBookingId(null);
  };

  return (
    <div>
      <Switch key={match.path}>
        <Route
          exact
          path={match.path}
          render={() => (
            <BookingsIndex
              bookingsByDays={bookingsByDays}
              selectedBookingId={selectedBookingId}
              clickBooking={clickBooking}
              clickAdd={clickAdd}
              clickEdit={clickEdit}
              clickDelete={clickDelete}
              initialized={initialized}
              filterDateFrom={filterDateFrom}
              setFilterDateFrom={setFilterDateFrom}
              filterDateTo={filterDateTo}
              setFilterDateTo={setFilterDateTo}
            />
          )}
        />
        <Route
          path={`${match.path}/edit-booking/:bookingId`}
          render={props => (
            <BookingsAdd
              {...props}
              title="Edit booking"
              userDetails={userDetails}
            />
          )}
        />
        <Route
          path={`${match.path}/add-booking`}
          render={props => (
            <BookingsAdd
              {...props}
              title="Add a booking"
              userDetails={userDetails}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export { Bookings };
