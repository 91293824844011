import React from "react";
import { Link, LogoLink } from "./elements/links";
import { Panel } from "./widgets/panel";
import { H1 } from "./elements/headers";
import { CenteredContent, P } from "./widgets/content";
import { PrimaryButton } from "./elements/forms";
import { signOutUser } from "../services/users";

const AccountSetup: React.FC<{ history: any }> = ({ history }) => (
  <div>
    <LogoLink />
    <Panel>
      <H1>Account setup</H1>
      <P>Welcome to LGP! To continue, you must create a field.</P>
      <P>
        If you're trying to access an existing field, ask a field administrator
        to send you an invite.
      </P>
      <PrimaryButton onClick={() => history.push("/field-setup")}>
        Create a new field
      </PrimaryButton>
      <CenteredContent>
        {`or `}
        <Link to="#" onClick={signOutUser}>
          Log out
        </Link>
      </CenteredContent>
    </Panel>
  </div>
);

export { AccountSetup };
