import styled from "styled-components";

const Panel = styled.div`
  margin: 0 25px 15px 25px;
  padding: 20px;

  background: #ffffff;
  border-top: 3px solid #4498d8;
  border-bottom: 3px solid #e1e1e1;
  border-radius: 0 0 10px 10px;
`;

export { Panel };
