import { Container } from "./widgets/page";
import React, { useEffect, useState } from "react";
import { CenteredWithPadding, P } from "./widgets/content";
import { Actions } from "./widgets/actions";
import { BasicH4, H1, H5, SolidH4 } from "./elements/headers";
import styled from "styled-components";
import { SelectableProps } from "../interfaces";
import { List, ListItem } from "./elements/lists";
import { Route, Switch } from "react-router-dom";
import { PackagesAdd } from "./packagesAdd";
import { deletePackage, getPackages } from "../services/packages";
import { EmptyState } from "./widgets/emptyState";
import { Subtext } from "./widgets/subtext";

const PackageName = styled.span<SelectableProps>`
  display: -webkit-box;
  -webkit-line-clamp: ${props => (props.isSelected ? "none" : "2")};
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: bold;
`;

const PackageCard = styled.div<SelectableProps>`
  padding: 10px;
  margin-bottom: 10px;

  background: ${props => (props.isSelected ? "#F5EDB7" : "#eeeeee")};
  border-radius: 5px;

  &:last-child {
    margin: 0;
  }
`;

const PackageSummary = styled.div`
  display: grid;
  grid-template-columns: 5fr 2fr 2fr;
  grid-column-gap: 5px;
`;

const PackageDetailContainer = styled.div<SelectableProps>`
  display: ${props => (props.isSelected ? "block" : "none")};
  margin-top: 10px;
  padding-top: 10px;

  border-top: 3px dotted #22252d;
`;

const Chip = styled.span`
  display: inline-block;
  margin-right: 5px;
  margin-bottom: 10px;
  padding: 3px 6px;

  border: 1px solid #22252d;
  border-radius: 5px;
  color: #22252d;
  font-size: 0.75rem;
  font-weight: bold;
`;

const PackageTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 10px;
  margin-bottom: 5px;
`;

const EquipmentContainer = styled.div`
  margin-bottom: 15px;
`;

const PartySize: React.FC<{
  partySize: string;
  leftAmount: string;
  rightAmount: string;
}> = ({ partySize, leftAmount, rightAmount }) => {
  const getPartySizeText = () => {
    switch (partySize) {
      case "specific":
        return "";
      case "any":
        return "Any";
      case "less than":
        return "Less than";
      case "more than":
        return "More than";
      case "range":
        return "From";
    }
  };

  return (
    <div>
      <Chip>{`${getPartySizeText()} ${leftAmount}${
        rightAmount ? ` to ${rightAmount}` : ""
      } player(s)`}</Chip>
    </div>
  );
};

const PackageDetail: React.FC<{
  pkg: any;
  isSelected: boolean;
  clickDelete: (id: string) => void;
  clickEdit: (id: string) => void;
}> = ({ pkg, isSelected, clickEdit, clickDelete }) => (
  <PackageDetailContainer isSelected={isSelected}>
    <P>{pkg.description}</P>
    <PackageTable>
      <H5>Duration</H5>
      <div>
        <Chip>
          {pkg.durationQuantity} {pkg.durationUnit}
        </Chip>
      </div>
      <H5>Days available</H5>
      <div>
        {pkg.daysOfTheWeek.map((day: any) => (
          <Chip key={day}>{day}</Chip>
        ))}
      </div>
      <H5>Party size</H5>
      <PartySize
        partySize={pkg.partySize}
        leftAmount={pkg.leftPartyAmount}
        rightAmount={pkg.rightPartyAmount}
      />
      {pkg.perHourPrice && (
        <>
          <H5>
            Price per
            <br /> additional hour
          </H5>
          <div>
            <Chip>${pkg.perHourPrice}</Chip>
          </div>
        </>
      )}
      {pkg.perPlayerPrice && (
        <>
          <H5>
            Price per
            <br />
            additional player
          </H5>
          <div>
            <Chip>${pkg.perPlayerPrice}</Chip>
          </div>
        </>
      )}
    </PackageTable>
    <EquipmentContainer>
      <H5>Equipment</H5>
      {!pkg.equipment.length && <P>No equipment included</P>}
      <List>
        {pkg.equipment.map((eq: any, i: number) => (
          <ListItem key={`${eq}-${i}`}>{eq}</ListItem>
        ))}
      </List>
    </EquipmentContainer>
    <Actions
      clickEdit={() => clickEdit(pkg.id)}
      clickDelete={() => clickDelete(pkg.id)}
    />
  </PackageDetailContainer>
);

const Package: React.FunctionComponent<{
  pkg: any;
  clickPackage: (packageId: string) => void;
  isSelected: boolean;
  clickEdit: (id: string) => void;
  clickDelete: (id: string) => void;
}> = ({ pkg, clickPackage, isSelected, clickEdit, clickDelete }) => {
  return (
    <PackageCard onClick={() => clickPackage(pkg.id)} isSelected={isSelected}>
      <PackageSummary>
        <PackageName isSelected={isSelected}>{pkg.name}</PackageName>
        <div>
          <BasicH4>{pkg.paintballQuantity}</BasicH4>
          <Subtext>paintballs</Subtext>
        </div>
        <div>
          <BasicH4>${pkg.price}</BasicH4>
          <Subtext>price</Subtext>
        </div>
      </PackageSummary>
      <PackageDetail
        pkg={pkg}
        isSelected={isSelected}
        clickEdit={clickEdit}
        clickDelete={clickDelete}
      />
    </PackageCard>
  );
};

const PackagesIndex: React.FC<{
  packages: any[];
  selectedPackageId: string;
  clickPackage: (packageId: string) => void;
  clickAdd: () => void;
  clickEdit: (packageId: string) => void;
  clickDelete: (packageId: string) => void;
  initialized: boolean;
}> = ({
  packages,
  selectedPackageId,
  clickPackage,
  clickAdd,
  clickEdit,
  clickDelete,
  initialized
}) => (
  <React.Fragment>
    <CenteredWithPadding>
      <H1>Packages</H1>
    </CenteredWithPadding>
    <Container>
      {!packages.length && initialized && (
        <EmptyState
          text="You haven't added any packages yet"
          buttonText="Add a package"
          icon="fas fa-box-open"
          onClick={clickAdd}
        />
      )}
      {!!packages.length && (
        <>
          <SolidH4>Weekly recurring packages</SolidH4>
          {packages.map(pkg => (
            <Package
              key={pkg.id}
              pkg={pkg}
              clickPackage={clickPackage}
              isSelected={selectedPackageId === pkg.id}
              clickEdit={clickEdit}
              clickDelete={clickDelete}
            />
          ))}
        </>
      )}
    </Container>
  </React.Fragment>
);

const Packages: React.FunctionComponent<{
  match: any;
  history: any;
  setIsLoading: (isLoading: boolean) => {};
  userDetails: any;
}> = ({ match, history, setIsLoading, userDetails }) => {
  const [selectedPackageId, setSelectedPackageId] = useState(null);
  const [packages, setPackages] = useState([]);
  const [initialized, setInitialized] = useState(false);

  const getAllPackages = async () => {
    setIsLoading(true);
    const allPackages = await getPackages(userDetails.fieldId);
    setIsLoading(false);
    setInitialized(true);

    setPackages(allPackages);
  };

  useEffect(() => {
    getAllPackages();
    // eslint-disable-next-line
  }, [match.isExact]);

  const clickPackage = (packageId: string): void => {
    if (packageId === selectedPackageId) {
      setSelectedPackageId(null);

      return;
    }

    setSelectedPackageId(packageId);
  };

  const clickAdd = () => history.push("/packages/add-package");

  const clickEdit = (packageId: string) => {
    history.push(`/packages/edit-package/${packageId}`);
  };

  const clickDelete = async (packageId: string) => {
    await deletePackage(packageId);
    await getAllPackages();

    setSelectedPackageId(null);
  };

  return (
    <div>
      <Switch key={match.path}>
        <Route
          exact
          path={match.path}
          render={() => (
            <PackagesIndex
              packages={packages}
              clickPackage={clickPackage}
              selectedPackageId={selectedPackageId}
              clickAdd={clickAdd}
              clickEdit={clickEdit}
              clickDelete={clickDelete}
              initialized={initialized}
            />
          )}
        />
        <Route
          path={`${match.path}/edit-package/:packageId`}
          render={props => (
            <PackagesAdd
              {...props}
              title="Edit package"
              userDetails={userDetails}
            />
          )}
        />
        <Route
          path={`${match.path}/add-package`}
          render={props => (
            <PackagesAdd
              {...props}
              title="Add a package"
              userDetails={userDetails}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export { Packages };
