import React from "react";
import { CenteredWithPadding, BasicP, P } from "./widgets/content";
import { H1, SolidH4 } from "./elements/headers";
import { Container } from "./widgets/page";

const Dashboard: React.FC = () => (
  <React.Fragment>
    <CenteredWithPadding>
      <H1>Dashboard</H1>
    </CenteredWithPadding>
    <Container>
      <SolidH4>Welcome</SolidH4>
      <P>LGP is currently in development.</P>
      <BasicP>
        Please be aware that any saved data could be lost until further notice.
      </BasicP>
    </Container>
  </React.Fragment>
);

export default Dashboard;
