import { H4 } from "./elements/headers";
import { InputControl } from "./elements/inputControl";
import { Checkbox, PrimaryButton, Select } from "./elements/forms";
import * as React from "react";
import { useEffect, useState } from "react";
import { useReducer } from "react";
import styled from "styled-components";
import { addField, updateField } from "../services/field";
import { dayFormatter } from "../utils/formatters";

const CityStateContainer = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-column-gap: 10px;
`;

const HoursContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 10px;
`;

const IsOpenContainer = styled.div`
  margin-bottom: 10px;

  font-weight: bold;
`;

const DayContainer = styled.span`
  text-transform: capitalize;
`;

const states = [
  "AL",
  "AK",
  "AS",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "DC",
  "FM",
  "FL",
  "GA",
  "GU",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MH",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "MP",
  "OH",
  "OK",
  "OR",
  "PW",
  "PA",
  "PR",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VI",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY"
];

const FieldEdit: React.FC<{
  field?: any;
  onSave: () => void;
}> = ({ field, onSave }) => {
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [addressLineOne, setAddressLineOne] = useState("");
  const [addressLineTwo, setAddressLineTwo] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  useEffect(() => {
    if (!!field) {
      setName(field.name);
      setPhoneNumber(field.phoneNumber);
      setEmail(field.email);
      setAddressLineOne(field.addressLineOne);
      setAddressLineTwo(field.addressLineTwo);
      setCity(field.city);
      setState(field.state);
      setPostalCode(field.postalCode);

      hoursOfOperationDispatch({
        type: "loadExisting",
        hoursOfOperation: field.hoursOfOperation
      });
    }
  }, [field]);

  const hoursOfOperationReducer = (state: any, action: any): any => {
    switch (action.type) {
      case "loadExisting":
        return action.hoursOfOperation;
      case "toggleIsOpen":
        return state.map((hours: any, i: number) => {
          if (action.day === i) {
            return {
              ...hours,
              isOpen: !hours.isOpen
            };
          }

          return hours;
        });
      case "setOpenTime":
        return state.map((hours: any, i: number) => {
          if (action.day === i) {
            return {
              ...hours,
              openTime: action.openTime
            };
          }

          return hours;
        });
      case "setCloseTime":
        return state.map((hours: any, i: number) => {
          if (action.day === i) {
            return {
              ...hours,
              openTime: action.closeTime
            };
          }

          return hours;
        });
      default:
        throw new Error();
    }
  };

  const DEFAULT_OPEN_TIME = "10:00";
  const DEFAULT_CLOSE_TIME = "17:00";

  const hoursOfOperationInitialState = [
    {
      isOpen: false,
      openTime: DEFAULT_OPEN_TIME,
      closeTime: DEFAULT_CLOSE_TIME
    },
    {
      isOpen: false,
      openTime: DEFAULT_OPEN_TIME,
      closeTime: DEFAULT_CLOSE_TIME
    },
    {
      isOpen: false,
      openTime: DEFAULT_OPEN_TIME,
      closeTime: DEFAULT_CLOSE_TIME
    },
    {
      isOpen: false,
      openTime: DEFAULT_OPEN_TIME,
      closeTime: DEFAULT_CLOSE_TIME
    },
    {
      isOpen: false,
      openTime: DEFAULT_OPEN_TIME,
      closeTime: DEFAULT_CLOSE_TIME
    },
    {
      isOpen: false,
      openTime: DEFAULT_OPEN_TIME,
      closeTime: DEFAULT_CLOSE_TIME
    },
    {
      isOpen: false,
      openTime: DEFAULT_OPEN_TIME,
      closeTime: DEFAULT_CLOSE_TIME
    }
  ];

  const [hoursOfOperation, hoursOfOperationDispatch] = useReducer(
    hoursOfOperationReducer,
    hoursOfOperationInitialState as any
  );

  const saveField = async () => {
    if (!!field) {
      await updateField({
        id: field.id,
        name,
        phoneNumber,
        email,
        addressLineOne,
        addressLineTwo,
        city,
        state,
        postalCode,
        hoursOfOperation
      });
    } else {
      await addField({
        name,
        phoneNumber,
        email,
        addressLineOne,
        addressLineTwo,
        city,
        state,
        postalCode,
        hoursOfOperation
      });
    }

    onSave();
  };

  return (
    <React.Fragment>
      <H4>Details</H4>
      <InputControl
        type="text"
        placeholder="Field name"
        value={name}
        onChange={(e: any) => setName(e.target.value)}
      />
      <H4>Contact information</H4>
      <InputControl
        type="number"
        placeholder="Phone number"
        value={phoneNumber}
        onChange={(e: any) => setPhoneNumber(e.target.value)}
      />
      <InputControl
        type="email"
        placeholder="Email"
        value={email}
        onChange={(e: any) => setEmail(e.target.value)}
      />
      <H4>Location</H4>
      <InputControl
        type="text"
        placeholder="Address line 1"
        value={addressLineOne}
        onChange={(e: any) => setAddressLineOne(e.target.value)}
      />
      <InputControl
        type="text"
        placeholder="Address line 2"
        value={addressLineTwo}
        onChange={(e: any) => setAddressLineTwo(e.target.value)}
      />
      <CityStateContainer>
        <InputControl
          type="text"
          placeholder="City"
          value={city}
          onChange={(e: any) => setCity(e.target.value)}
        />
        <Select value={state} onChange={(e: any) => setState(e.target.value)}>
          <option disabled value="">
            State
          </option>
          {states.map((state: any) => (
            <option key={state} value={state}>
              {state}
            </option>
          ))}
        </Select>
      </CityStateContainer>
      <InputControl
        type="text"
        placeholder="Postal code"
        value={postalCode}
        onChange={(e: any) => setPostalCode(e.target.value)}
      />
      <H4>Hours of operation</H4>
      <>
        {hoursOfOperation.map((dayDetails: any, day: any) => (
          <React.Fragment key={day}>
            <IsOpenContainer>
              <Checkbox
                checked={dayDetails.isOpen}
                onChange={(e: any) =>
                  hoursOfOperationDispatch({
                    type: "toggleIsOpen",
                    day
                  })
                }
              />
              {` Open `}
              <DayContainer>{dayFormatter(day)}</DayContainer>
            </IsOpenContainer>
            {dayDetails.isOpen && (
              <HoursContainer>
                <InputControl
                  type="time"
                  value={dayDetails.openTime}
                  onChange={(e: any) =>
                    hoursOfOperationDispatch({
                      type: "setOpenTime",
                      day,
                      openTime: e.target.value
                    })
                  }
                />
                <InputControl
                  type="time"
                  value={dayDetails.closeTime}
                  onChange={(e: any) =>
                    hoursOfOperationDispatch({
                      type: "setCloseTime",
                      day,
                      closeTime: e.target.value
                    })
                  }
                />
              </HoursContainer>
            )}
          </React.Fragment>
        ))}
      </>
      <PrimaryButton onClick={saveField}>Save</PrimaryButton>
    </React.Fragment>
  );
};

export { FieldEdit };
