import firebase from "../firebase";
import { client } from "../algolia";
import functions from "../firebase";
import { customerHasBookings } from "./bookings";

const db = firebase.firestore();

const addCustomer = async (fieldId: string, customer: any) =>
  await db.collection("customers").add({
    field_id: fieldId,
    first_name: customer.firstName,
    last_name: customer.lastName,
    phone_number: customer.phoneNumber,
    email: customer.email
  });

const updateCustomer = async (fieldId: string, customer: any) =>
  await db
    .collection("customers")
    .doc(customer.id)
    .update({
      field_id: fieldId,
      first_name: customer.firstName,
      last_name: customer.lastName,
      phone_number: customer.phoneNumber,
      email: customer.email
    });

const getCustomers = async (fieldId: string) => {
  const querySnapshot = await db
    .collection("customers")
    .where("field_id", "==", fieldId)
    .orderBy("last_name")
    .get();

  return querySnapshot.docs.map((doc: any) => {
    const data = doc.data();

    return {
      id: doc.id,
      firstName: data.first_name,
      lastName: data.last_name,
      phoneNumber: data.phone_number,
      email: data.email
    };
  });
};

const getCustomer = async (fieldId: string, customerId: string) => {
  const customerDoc = await db
    .collection("customers")
    .doc(customerId)
    .get();

  const data = customerDoc.data();

  return {
    id: customerDoc.id,
    firstName: data.first_name,
    lastName: data.last_name,
    phoneNumber: data.phone_number,
    email: data.email
  };
};

const deleteCustomer = async (fieldId: string, customerId: string) => {
  if (await customerHasBookings(fieldId, customerId)) {
    return;
  }

  const deleteFromCustomersIndex = functions
    .functions()
    .httpsCallable("deleteFromCustomersIndex");

  await deleteFromCustomersIndex({ customerId });

  await db
    .collection("customers")
    .doc(customerId)
    .delete();
};

const findCustomers = async (
  fieldId: string,
  query: string
): Promise<any[]> => {
  const index = client.initIndex("customers");
  const responses = await index.search({
    query,
    filters: `field_id:${fieldId}`
  });

  return responses.hits.map((data: any) => ({
    id: data.objectID,
    firstName: data.first_name,
    lastName: data.last_name,
    phoneNumber: data.phone_number,
    email: data.email
  }));
};

export {
  addCustomer,
  updateCustomer,
  getCustomers,
  getCustomer,
  deleteCustomer,
  findCustomers
};
