import React, { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { withRouter } from "react-router-dom";

import { H1, H4, H5 } from "./elements/headers";
import { InputControl } from "./elements/inputControl";
import {
  PrimaryButton,
  CondensedPrimaryButton,
  Select,
  DeleteButton
} from "./elements/forms";
import { CenteredWithPadding, P } from "./widgets/content";
import { Container } from "./widgets/page";
import {
  addBooking,
  getBooking,
  Timestamp,
  updateBooking
} from "../services/bookings";
import { getPackages } from "../services/packages";
import { Anchor, Link } from "./elements/links";
import { findCustomers } from "../services/customers";
import Popper from "@material-ui/core/Popper";
import { ClickAwayListener } from "@material-ui/core";
import { ActionIcon } from "./widgets/icons";

const PackagesContainer = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr;
  column-gap: 10px;
`;

const CustomerPopper = styled(Popper)`
  margin: 0 15px;

  z-index: 5;
`;

const PopperContent = styled.div`
  display: grid;
  grid-row-gap: 15px;
  margin: 10px auto 0;
  padding: 15px;

  background: #ffffff;
  color: #22252d;
  box-shadow: rgba(16, 36, 94, 0.4) 0px 2px 6px 0px;
  font-family: "Roboto", sans-serif;
`;

const CustomerTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 10px;
  margin-bottom: 5px;
`;

const CustomerContainer = styled.div`
  margin-bottom: 15px;
`;

const BookingsAddComponent = ({ match, history, title, userDetails }) => {
  const packageInitialState = { id: "", name: "", quantity: 1 };

  const [bookingId, setBookingId] = useState("");
  const [name, setName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [date, setDate] = useState(moment().format("YYYY-MM-DD"));
  const [time, setTime] = useState(moment().format("HH:00"));
  const [packageOptions, setPackageOptions] = useState([]);
  const [selectedPackages, setSelectedPackages] = useState([
    packageInitialState
  ]);
  const [timeoutCallback, setTimeoutCallback] = useState(null);
  const [customerResults, setCustomerResults] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [customersIsLoading, setCustomersIsLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);

  const getBookingToEdit = async () => {
    const booking = await getBooking(
      userDetails.fieldId,
      match.params.bookingId
    );

    setBookingId(booking.id);
    setName(booking.name);
    setSelectedPackages(booking.packages);
    setSelectedCustomer(booking.customer);
  };

  const getAllPackages = async () => {
    const allPackages = await getPackages(userDetails.fieldId);
    const options = allPackages.map(pkg => ({
      id: pkg.id,
      name: pkg.name
    }));

    setPackageOptions(options);
  };

  useEffect(() => {
    if (match.params.bookingId) {
      getBookingToEdit();
    }

    getAllPackages();
    // eslint-disable-next-line
  }, []);

  const changePackage = (desiredPackageId, index) => {
    const packages = selectedPackages.map((pkg, i) => {
      if (index !== i) {
        return pkg;
      }

      const desiredPackage = packageOptions.find(
        option => option.id === desiredPackageId
      );

      return { ...pkg, ...desiredPackage };
    });

    setSelectedPackages(packages);
  };

  const updatePackageQuantity = (quantity, index) => {
    const packages = selectedPackages.map((pkg, i) => {
      if (index !== i) {
        return pkg;
      }
      return { ...pkg, quantity };
    });

    setSelectedPackages(packages);
  };

  const saveBooking = async () => {
    if (bookingId) {
      await updateBooking(userDetails.fieldId, {
        id: bookingId,
        name,
        appointment: Timestamp.fromDate(moment(`${date} ${time}`).toDate()),
        phoneNumber,
        packages: selectedPackages,
        customer: selectedCustomer
      });
    } else {
      await addBooking(userDetails.fieldId, {
        name,
        appointment: Timestamp.fromDate(moment(`${date} ${time}`).toDate()),
        phoneNumber,
        packages: selectedPackages,
        customer: selectedCustomer
      });
    }

    history.push("/bookings");
  };

  const addPackage = () =>
    setSelectedPackages([...selectedPackages, packageInitialState]);

  const updatePhoneNumber = newPhoneNumber => {
    setPhoneNumber(newPhoneNumber);

    if (newPhoneNumber.length <= 1) {
      setCustomerResults([]);

      return;
    }

    setCustomersIsLoading(true);

    if (timeoutCallback) {
      clearTimeout(timeoutCallback);
    }

    const timeout = setTimeout(async () => {
      const results = await findCustomers(userDetails.fieldId, phoneNumber);
      setCustomerResults(results);
      setCustomersIsLoading(false);
    }, 1000);

    setTimeoutCallback(timeout);
  };

  const selectCustomer = customer => {
    setSelectedCustomer(customer);
    setAnchorEl(null);
  };

  const packageNotSelected = (packageId, optionId) =>
    !selectedPackages.find(
      selectedPackage => selectedPackage.id === optionId
    ) || packageId === optionId;

  return (
    <>
      <CenteredWithPadding>
        <H1>{title}</H1>
      </CenteredWithPadding>
      <Container>
        <div>
          <H4>Contact information</H4>
          <InputControl
            type="text"
            placeholder="Party name"
            value={name}
            onChange={e => setName(e.target.value)}
          />
          {!selectedCustomer && (
            <>
              <P>
                Lookup a customer by their phone number, or{" "}
                <Link to="/customers/add-customer">add a new customer</Link>.
              </P>
              <InputControl
                type="tel"
                placeholder="Phone number"
                value={phoneNumber}
                onChange={e => updatePhoneNumber(e.target.value)}
                onFocus={e => setAnchorEl(e.currentTarget)}
                onKeyDown={e => {
                  if (e.key === "Escape") {
                    setAnchorEl(null);
                  }
                }}
              />
            </>
          )}
          <CustomerPopper
            open={!!anchorEl}
            anchorEl={anchorEl}
            placement="bottom-start"
            onClose={() => setAnchorEl(null)}
          >
            <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
              <PopperContent>
                {customersIsLoading && "Loading..."}
                {!customersIsLoading &&
                  customerResults.map(customer => (
                    <div
                      key={customer.id}
                      onClick={() => selectCustomer(customer)}
                    >{`${customer.lastName}, ${customer.firstName}`}</div>
                  ))}
                {!customerResults.length && !customersIsLoading && (
                  <>
                    <div>
                      {`No customers found. `}
                      <Link to="/customers/add-customer">Add a customer</Link>.
                    </div>
                  </>
                )}
              </PopperContent>
            </ClickAwayListener>
          </CustomerPopper>
          {!!selectedCustomer && (
            <CustomerContainer>
              <H4>Customer</H4>
              <CustomerTable>
                <H5>Name</H5>
                <P>
                  {`${selectedCustomer.firstName} ${selectedCustomer.lastName}`}
                </P>
                <H5>Phone number</H5>
                <Anchor href={`tel:${selectedCustomer.phoneNumber}`}>
                  {selectedCustomer.phoneNumber}
                </Anchor>
                <H5>Email</H5>
                <Anchor href={`mailto:${selectedCustomer.email}`}>
                  {selectedCustomer.email}
                </Anchor>
              </CustomerTable>
              <DeleteButton onClick={() => setSelectedCustomer(null)}>
                <ActionIcon className="fas fa-user-times" /> Remove customer
              </DeleteButton>
            </CustomerContainer>
          )}
          <H4>Appointment</H4>
          <InputControl
            type="date"
            value={date}
            onChange={e => setDate(e.target.value)}
          />
          <InputControl
            type="time"
            value={time}
            onChange={e => setTime(e.target.value)}
          />
          <H4>Packages</H4>
          {!packageOptions.length && (
            <div>
              <P>
                You must have available packages in order to create a booking.{" "}
                <Link to="/packages/add-package">Add a package</Link>
              </P>
            </div>
          )}
          <PackagesContainer>
            {selectedPackages.map((pkg, i) => (
              <React.Fragment key={pkg.id}>
                <Select
                  value={pkg.id}
                  onChange={e => changePackage(e.target.value, i)}
                  disabled={!packageOptions.length}
                >
                  <option value="" disabled>
                    Select an option
                  </option>
                  {packageOptions
                    .filter(option => packageNotSelected(pkg.id, option.id))
                    .map(option => (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    ))}
                </Select>
                <InputControl
                  type="number"
                  placeholder="Quantity"
                  value={pkg.quantity}
                  onChange={e => updatePackageQuantity(e.target.value, i)}
                  disabled={!packageOptions.length}
                />
              </React.Fragment>
            ))}
          </PackagesContainer>
          <CondensedPrimaryButton
            disabled={
              !packageOptions.length ||
              selectedPackages.length === packageOptions.length
            }
            onClick={addPackage}
          >
            + add a package
          </CondensedPrimaryButton>
          <PrimaryButton
            disabled={!packageOptions.length || !selectedCustomer}
            onClick={saveBooking}
          >
            Save
          </PrimaryButton>
        </div>
      </Container>
    </>
  );
};

const BookingsAdd = withRouter(BookingsAddComponent);

export { BookingsAdd };
