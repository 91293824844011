import * as React from "react";
import { Container, PageWrapper } from "./widgets/page";
import { Route, Switch } from "react-router-dom";
import { FieldEdit } from "./fieldEdit";
import { CenteredWithPadding } from "./widgets/content";
import { H1, H5, SolidH4 } from "./elements/headers";
import { ContactInformation } from "./widgets/contactInformation";
import styled from "styled-components";
import { BasicPrimaryButton } from "./elements/forms";
import { ActionIcon } from "./widgets/icons";
import { useEffect, useState } from "react";
import { getField } from "../services/field";
import moment from "moment";
import { dayFormatter } from "../utils/formatters";
import { EmptyState } from "./widgets/emptyState";

const AddressContainer = styled.div`
  margin-bottom: 5px;
`;

const LastItem = styled.div`
  margin-bottom: 10px;
`;

const FieldIndex: React.FC<{
  field: any;
  clickEdit: () => void;
  initialized: boolean;
}> = ({ field, clickEdit, initialized }) => (
  <React.Fragment>
    <CenteredWithPadding>
      <H1>Field</H1>
    </CenteredWithPadding>
    <Container>
      {initialized && (!field || !field.id) && (
        <EmptyState
          text="Looks like you haven't setup your field yet"
          buttonText="Add field information"
          icon="fas fa-store-alt"
          onClick={clickEdit}
        />
      )}
      {!!field && field.id && (
        <>
          <SolidH4>{field.name}</SolidH4>
          <H5>Contact information</H5>
          <ContactInformation>
            <H5>Email</H5>
            <span>{field.email}</span>
            <H5>Phone</H5>
            <span>{field.phoneNumber}</span>
            <H5>Address</H5>
            <div>
              <AddressContainer>{field.addressLineOne}</AddressContainer>
              <AddressContainer>{field.addressLineTwo}</AddressContainer>
              <LastItem>{`${field.city}, ${field.state} ${field.postalCode}`}</LastItem>
            </div>
          </ContactInformation>
          <H5>Hours of operation</H5>
          <ContactInformation>
            {field.hoursOfOperation.map((hours: any, day: number) => {
              if (!hours.isOpen) {
                return null;
              }

              return (
                <React.Fragment key={day}>
                  <span>{dayFormatter(day)}</span>
                  <LastItem>
                    {`${moment(hours.openTime, "HH:mm").format(
                      "h:mma"
                    )} - ${moment(hours.closeTime, "HH:mm").format("h:mma")}`}
                  </LastItem>
                </React.Fragment>
              );
            })}
          </ContactInformation>
          <BasicPrimaryButton onClick={clickEdit}>
            <ActionIcon className="fa fa-pencil-alt fa-sm" />
            Edit
          </BasicPrimaryButton>
        </>
      )}
    </Container>
  </React.Fragment>
);

const Field: React.FunctionComponent<{
  history: any;
  match: any;
  setIsLoading: (isLoading: boolean) => void;
}> = ({ history, match, setIsLoading }) => {
  const [fieldDetails, setFieldDetails] = useState(null);
  const [initialized, setInitialized] = useState(false);

  const getFieldDetails = async () => {
    setIsLoading(true);
    const field = await getField();
    setIsLoading(false);
    setInitialized(true);

    setFieldDetails(field);
  };

  useEffect(() => {
    getFieldDetails();
    // eslint-disable-next-line
  }, [match.isExact]);

  const clickEdit = () => history.push(`${match.path}/edit-field/`);

  return (
    <div>
      <Switch>
        <Route
          exact
          path={match.path}
          render={() => (
            <FieldIndex
              field={fieldDetails}
              clickEdit={clickEdit}
              initialized={initialized}
            />
          )}
        />
        <Route
          path={`${match.path}/edit-field/`}
          render={props => (
            <PageWrapper title="Edit field">
              <FieldEdit
                {...props}
                field={fieldDetails}
                onSave={() => history.push("/field")}
              />
            </PageWrapper>
          )}
        />
      </Switch>
    </div>
  );
};

export { Field };
