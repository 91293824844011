import styled, { css, keyframes } from "styled-components";
import * as React from "react";
import { useEffect, useRef, useState } from "react";

const expand = keyframes`
  from { width: 0; }
  to { width: 100%; } 
`;

const topAnimation = css`
  animation: ${expand} 3s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
`;
const bottomAnimation = css`
  animation: ${expand} 0.5s cubic-bezier(0.645, 0.045, 0.355, 1) forwards;
  opacity: 0;
  transition: opacity 1s 0.5s;
`;

// eslint-disable-next-line no-unexpected-multiline
const ProgressBarTop = styled.div<{
  startedLoading: boolean;
  isLoading: boolean;
}>`
  position: fixed;
  top: 0;
  height: 5px;
  width: 0;

  background: #ffffff;
  z-index: 4;

  ${props => (props.startedLoading ? topAnimation : "")}
  ${props =>
    !props.isLoading && props.startedLoading
      ? "opacity: 0; transition: opacity .5s .25s;"
      : ""}
`;

// eslint-disable-next-line no-unexpected-multiline
const ProgressBarBottom = styled.div<{
  startedLoading: boolean;
  isLoading: boolean;
}>`
  position: fixed;
  top: 0;
  height: 5px;
  width: 0;

  background: #ffffff;
  z-index: 4;

  ${props => (!props.isLoading && props.startedLoading ? bottomAnimation : "")}
`;

const ProgressBar: React.FC<{
  isLoading: boolean;
}> = ({ isLoading }) => {
  const [startedLoading, setStartedLoading] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (isLoading && !startedLoading) {
      setStartedLoading(true);
    }

    if (!isLoading && startedLoading) {
      timerRef.current = setTimeout(() => setStartedLoading(false), 2500);

      return () => clearTimeout(timerRef.current);
    }
    // eslint-disable-next-line
  }, [isLoading]);

  return (
    <>
      <ProgressBarTop startedLoading={startedLoading} isLoading={isLoading} />
      <ProgressBarBottom
        startedLoading={startedLoading}
        isLoading={isLoading}
      />
      {/*<Backdrop startedLoading={startedLoading} isLoading={isLoading} />*/}
    </>
  );
};

export { ProgressBar };
