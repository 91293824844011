import React, { useEffect, useRef, useState } from "react";
import { CenteredWithPadding, P } from "./widgets/content";
import { H1, H4 } from "./elements/headers";
import { Container } from "./widgets/page";
import { InputControl } from "./elements/inputControl";
import {
  BasicPrimaryButton,
  Checkbox,
  CondensedPrimaryButton,
  ErrorMessage,
  TextArea
} from "./elements/forms";
import styled from "styled-components";
import { Image } from "./elements/image";
import {
  PerCheckboxContainer,
  PerContainer
} from "./widgets/pricePerContainer";
import {
  addEquipment,
  getSingleEquipment,
  updateEquipment
} from "../services/equipment";

const ImageContainer = styled.div`
  max-height: 250px;

  margin-bottom: 15px;
`;

const ImageButtonContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
`;

const RemoveImageButton = styled(CondensedPrimaryButton)`
  background: #d8443b;
`;

const EquipmentAdd: React.FC<{
  match: any;
  history: any;
  title: string;
  setIsLoading: (isLoading: boolean) => void;
  userDetails: any;
}> = ({ match, history, title, setIsLoading, userDetails }) => {
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [quantity, setQuantity] = useState("");
  const [price, setPrice] = useState("");
  const [file, setFile] = useState(null);
  const [fileURL, setFileURL] = useState("");
  const [fileName, setFileName] = useState("");
  const [priceEnabled, setPriceEnabled] = useState(false);
  const [deleteImage, setDeleteImage] = useState(false);
  const [imageError, setImageError] = useState(false);
  const [failureMessage, setFailureMessage] = useState("");

  const fileRef = useRef(null);

  const getEquipmentToEdit = async () => {
    setIsLoading(true);
    const equipment = await getSingleEquipment(match.params.equipmentId);

    setId(equipment.id);
    setName(equipment.name);
    setDescription(equipment.description);
    setQuantity(equipment.quantity);
    setPrice(equipment.price);
    setPriceEnabled(!!equipment.price);
    setFileURL(equipment.fileURL);
    setFileName(equipment.fileName);

    setIsLoading(false);
  };

  useEffect(() => {
    if (match.params.equipmentId) {
      getEquipmentToEdit();
    }

    // eslint-disable-next-line
  }, []);

  const updateFile = (file: any) => {
    setImageError(false);
    setFile(file);
    setFileURL(URL.createObjectURL(file));
  };

  const saveEquipment = async () => {
    setFailureMessage("");
    setIsLoading(true);

    try {
      if (id) {
        await updateEquipment({
          id,
          name,
          description,
          quantity,
          price,
          file,
          deleteImage,
          fileName
        });
      } else {
        await addEquipment(userDetails.fieldId, {
          name,
          description,
          quantity,
          price,
          file
        });
      }

      setIsLoading(false);
    } catch (e) {
      if (e.message === "Image is too large") {
        setFailureMessage(
          "Failed to save equipment. The image provided must be less than 2MB."
        );
      }

      return;
    }

    history.push("/equipment");
  };

  const removeImage = () => {
    setImageError(false);
    setDeleteImage(true);
    setFile(null);
    setFileURL("");
  };

  return (
    <React.Fragment>
      <CenteredWithPadding>
        <H1>{title}</H1>
      </CenteredWithPadding>
      <Container>
        <H4>Details</H4>
        <InputControl
          type="text"
          placeholder="Equipment name"
          value={name}
          onChange={(e: any) => setName(e.target.value)}
        />
        <P>
          Describe the equipment and provide any additional information to
          potential customers.
        </P>
        <TextArea
          onChange={(e: any) => setDescription(e.target.value)}
          placeholder="Equipment description"
          rows={5}
          value={description}
        />

        <InputControl
          type="number"
          placeholder="Quantity"
          value={quantity}
          onChange={(e: any) => setQuantity(e.target.value)}
        />
        {!fileURL && !imageError && <P>There isn't an image, yet.</P>}
        {imageError && <P>Failed to load image</P>}
        {!!fileURL && !imageError && (
          <ImageContainer>
            <Image
              style={{ maxHeight: "250px" }}
              src={fileURL}
              alt="Uploaded equipment image preview"
              onError={() => setImageError(true)}
            />
          </ImageContainer>
        )}
        <ImageButtonContainer>
          <CondensedPrimaryButton onClick={() => fileRef.current.click()}>
            Set image
          </CondensedPrimaryButton>
          <RemoveImageButton disabled={!fileURL} onClick={removeImage}>
            Remove image
          </RemoveImageButton>
        </ImageButtonContainer>
        <input
          ref={fileRef}
          style={{ display: "none" }}
          type="file"
          accept="image/*"
          onChange={e => updateFile(e.target.files[0])}
        />
        <H4>Price</H4>
        <PerContainer>
          <P>
            <PerCheckboxContainer>
              <Checkbox
                checked={priceEnabled}
                onChange={() => setPriceEnabled(!priceEnabled)}
              />
            </PerCheckboxContainer>
            When customers are booking, make available as an add-on to a
            selected package
          </P>
        </PerContainer>
        <InputControl
          disabled={!priceEnabled}
          type="number"
          placeholder="Price"
          value={price}
          onChange={(e: any) => setPrice(e.target.value)}
        />
        {failureMessage && <ErrorMessage>{failureMessage}</ErrorMessage>}
        <BasicPrimaryButton onClick={saveEquipment}>Save</BasicPrimaryButton>
      </Container>
    </React.Fragment>
  );
};

export { EquipmentAdd };
