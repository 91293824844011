import styled from "styled-components";

const ImageContainer = styled.div`
  background: #ffffff;
  border-radius: 5px;

  &:before {
    content: "";
    display: block;
    padding-bottom: 100%;
  }
`;

export { ImageContainer };
