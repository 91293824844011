import firebase from "../firebase";
import moment from "moment";

const db = firebase.firestore();
const storageRef = firebase.storage().ref();

const addEquipment = async (fieldId: string, equipment: any) => {
  if (equipment.file && equipment.file.size > 2 * 1024 * 1024) {
    throw Error("Image is too large");
  }

  const doc = await db.collection("equipment").add({
    field_id: fieldId,
    name: equipment.name,
    description: equipment.description,
    quantity: equipment.quantity,
    price: equipment.price
  });

  if (equipment.file) {
    const fileToSave = new File([equipment.file], doc.id);
    const fileName = `${doc.id}${moment().unix()}`;
    const meta = {
      contentType: equipment.file.type
    };

    await storageRef.child(`images/${fileName}`).put(fileToSave, meta);

    const fileURL = await storageRef
      .child(`images/${fileName}`)
      .getDownloadURL();

    await db
      .collection("equipment")
      .doc(doc.id)
      .update({
        file_name: fileName,
        file_url: fileURL
      });
  }
};

const getEquipment = async (fieldId: string) => {
  const querySnapshot = await db
    .collection("equipment")
    .where("field_id", "==", fieldId)
    .get();

  return querySnapshot.docs.map(doc => {
    const data = doc.data();

    return {
      id: doc.id,
      name: data.name,
      description: data.description,
      quantity: data.quantity,
      price: data.price,
      fileName: data.file_name,
      fileURL: data.file_url
    };
  });
};

const getSingleEquipment = async (equipmentId: string) => {
  const doc = await db
    .collection("equipment")
    .doc(equipmentId)
    .get();

  const data = doc.data();

  return {
    id: doc.id,
    name: data.name,
    description: data.description,
    quantity: data.quantity,
    price: data.price,
    fileName: data.file_name,
    fileURL: data.file_url
  };
};

const deleteEquipment = async (equipmentId: string) => {
  const equipment = await getSingleEquipment(equipmentId);
  const image = storageRef.child(`images/${equipment.fileName}`);

  if (!!image) {
    await image.delete();
  }

  return await db
    .collection("equipment")
    .doc(equipmentId)
    .delete();
};

const updateEquipment = async (equipment: any) => {
  if (equipment.file && equipment.file.size > 2 * 1024 * 1024) {
    throw Error("Image is too large");
  }

  await db
    .collection("equipment")
    .doc(equipment.id)
    .update({
      name: equipment.name,
      description: equipment.description,
      quantity: equipment.quantity,
      price: equipment.price
    });

  if ((equipment.deleteImage || equipment.file) && equipment.fileName) {
    const image = storageRef.child(`images/${equipment.fileName}`);

    if (!!image) {
      await image.delete();
    }
  }

  if (equipment.file) {
    const fileName = `${equipment.id}${moment().unix()}`;
    const fileToSave = new File([equipment.file], equipment.id);
    const meta = {
      contentType: equipment.file.type
    };

    await storageRef.child(`images/${fileName}`).put(fileToSave, meta);

    const fileURL = await storageRef
      .child(`images/${fileName}`)
      .getDownloadURL();

    await db
      .collection("equipment")
      .doc(equipment.id)
      .update({
        file_name: fileName,
        file_url: fileURL
      });
  } else {
    await db
      .collection("equipment")
      .doc(equipment.id)
      .update({
        file_name: "",
        file_url: ""
      });
  }
};

export {
  addEquipment,
  getEquipment,
  deleteEquipment,
  getSingleEquipment,
  updateEquipment
};
