import firebase from "../firebase";

const db = firebase.firestore();

const createUser = async (user: any) => {
  const creds = await firebase
    .auth()
    .createUserWithEmailAndPassword(user.email, user.password);

  const newUser = creds.user;

  if (!newUser) {
    throw Error();
  }

  await newUser.sendEmailVerification();

  await newUser.updateProfile({
    displayName: user.name
  });

  await firebase.auth().signOut();
};

const loginUser = async ({ email, password }: any) => {
  const creds = await firebase
    .auth()
    .signInWithEmailAndPassword(email, password);

  if (!creds.user.emailVerified) {
    firebase.auth().signOut();

    throw Error("email-not-verified");
  }

  return true;
};

const signOutUser = async () => {
  await firebase.auth().signOut();
};

const addUserDetails = async (userDetails: any) => {
  return await db.collection("user_details").add({
    uid: firebase.auth().currentUser.uid,
    field_id: userDetails.fieldId
  });
};

const getUserDetails = async () => {
  const querySnapshot = await db
    .collection("user_details")
    .where("uid", "==", firebase.auth().currentUser.uid)
    .get();

  return querySnapshot.docs.map((doc: any) => {
    const data = doc.data();

    return {
      uid: data.uid,
      fieldId: data.field_id
    };
  })[0];
};

export { createUser, loginUser, signOutUser, getUserDetails, addUserDetails };
