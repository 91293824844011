import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { History } from "history";

import { H1 } from "./elements/headers";
import { Panel } from "./widgets/panel";
import { InputControl } from "./elements/inputControl";
import { PrimaryButton } from "./elements/forms";
import { Link } from "./elements/links";
import { CenteredContent, P } from "./widgets/content";
import { isValidEmail } from "../utils/validators";
import { LogoLink } from "./elements/links";
import { Snackbar } from "./widgets/snackbar";
import { getUserDetails, loginUser } from "../services/users";

export const Login: React.FC<{ history: History }> = ({ history }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const [loginError, setLoginError] = useState("");

  const getIsOnboarded = async () => {
    const userDetails = await getUserDetails();

    return userDetails && userDetails.fieldId;
  };

  const attemptLogin = async (): Promise<void> => {
    const hasValidEmail = isValidEmail(email);
    const hasValidPassword = !!password.length;

    setEmailError("");
    setPasswordError("");

    if (!hasValidEmail) {
      setEmailError("Invalid email");
    }

    if (!hasValidPassword) {
      setPasswordError("Invalid password");
    }

    if (!hasValidEmail || !hasValidPassword) {
      return;
    }

    let loginSuccess = false;

    try {
      loginSuccess = await loginUser({ email, password });
    } catch (e) {
      const key = !!e.code ? e.code : e.message;
      const errorMessage = getErrorMessage(key);

      setLoginError(errorMessage);
    }

    if (loginSuccess) {
      const isOnboarded = await getIsOnboarded();

      if (isOnboarded) {
        history.push("/dashboard");
      } else if (loginSuccess && !isOnboarded) {
        history.push("/account-setup");
      }
    }
  };

  const getErrorMessage = (code: string): string => {
    switch (code) {
      case "auth/user-disabled":
        return "The account provided is currently disabled.";
      case "auth/invalid-email":
        return "The email provided is invalid.";
      case "email-not-verified":
        return "You must confirm your email before you can access the site.";
      default:
        return "The email and/or password provided does not match our records.";
    }
  };

  return (
    <div>
      <LogoLink />
      <Panel>
        <H1>Login</H1>
        <P>Welcome back!</P>
        <form onSubmit={e => e.preventDefault()}>
          <InputControl
            errorMessage={emailError}
            type="text"
            placeholder="Email"
            onChange={(e: any) => setEmail(e.target.value)}
          />
          <InputControl
            errorMessage={passwordError}
            type="password"
            placeholder="Password"
            onChange={(e: any) => setPassword(e.target.value)}
          />
          <PrimaryButton onClick={attemptLogin}>Sign in</PrimaryButton>
        </form>
        <CenteredContent>
          or <Link to="/sign-up">Sign up</Link>
        </CenteredContent>
      </Panel>
      <CenteredContent>
        <Link to="/forgot-password">Forgot password?</Link>
      </CenteredContent>
      <Snackbar
        open={!!loginError.length}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center"
        }}
        message={loginError}
        autoHideDuration={6000}
        onClose={() => setLoginError("")}
      />
    </div>
  );
};

export default withRouter(Login);
