import styled from "styled-components";

const List = styled.ul`
  margin: 0;
  padding: 0;

  list-style: none;
`;

const ListItem = styled.li`
  padding: 5px 10px;

  background: rgba(34, 37, 45, 0.1);
  border-left: 1px solid #22252d;
  border-radius: 5px;

  & + & {
    margin-top: 10px;
  }
`;

export { List, ListItem };
