import firebase from "../firebase";

export const getAccount = () => {
  const { displayName: name, email } = firebase.auth().currentUser;

  return {
    name,
    email
  };
};
