import styled from "styled-components";

const ContactInformation = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 10px;

  margin-bottom: 5px;
`;

export { ContactInformation };
