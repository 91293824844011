import firebase from "../firebase";
import { addUserDetails } from "./users";

const db = firebase.firestore();

const getField = async () => {
  const querySnapshot = await db
    .collection("fields")
    .where("uid", "==", firebase.auth().currentUser.uid)
    .get();

  return querySnapshot.docs.map(doc => {
    const data = doc.data();

    return {
      id: doc.id,
      name: data.name,
      phoneNumber: data.phone_number,
      email: data.email,
      addressLineOne: data.address_line_one,
      addressLineTwo: data.address_line_two,
      city: data.city,
      state: data.state,
      postalCode: data.postal_code,
      hoursOfOperation: data.hours_of_operation.map((hours: any) => ({
        isOpen: hours.is_open,
        openTime: hours.open_time,
        closeTime: hours.close_time
      }))
    };
  })[0];
};

const addField = async (field: any) => {
  const newField = await db.collection("fields").add({
    uid: firebase.auth().currentUser.uid,
    name: field.name,
    phone_number: field.phoneNumber,
    email: field.email,
    address_line_one: field.addressLineOne,
    address_line_two: field.addressLineTwo,
    city: field.city,
    state: field.state,
    postal_code: field.postalCode,
    hours_of_operation: field.hoursOfOperation.map((hours: any) => ({
      is_open: hours.isOpen,
      open_time: hours.openTime,
      close_time: hours.closeTime
    }))
  });

  await addUserDetails({ fieldId: newField.id });

  return newField;
};

const updateField = async (field: any) => {
  return await db
    .collection("fields")
    .doc(field.id)
    .update({
      uid: firebase.auth().currentUser.uid,
      name: field.name,
      phone_number: field.phoneNumber,
      email: field.email,
      address_line_one: field.addressLineOne,
      address_line_two: field.addressLineTwo,
      city: field.city,
      state: field.state,
      postal_code: field.postalCode,
      hours_of_operation: field.hoursOfOperation.map((hours: any) => ({
        is_open: hours.isOpen,
        open_time: hours.openTime,
        close_time: hours.closeTime
      }))
    });
};

export { getField, addField, updateField };
