import * as React from "react";
import styled from "styled-components";
import { Link as RouterLink } from "react-router-dom";

import { ReactComponent as Logo } from "../../images/full_logo_dark.svg";

const Anchor = styled.a`
  color: #22252d;

  &:visited {
    color: #22252d;
  }

  &:hover {
    text-decoration: none;
  }
`;

const Link = styled(RouterLink)`
  color: #22252d;

  &:visited {
    color: #22252d;
  }

  &:hover {
    text-decoration: none;
  }
`;

const LogoContainer = styled.div`
  margin: 0 75px;
  padding: 50px 0;
`;

const CondensedLogoContainer = styled.div`
  margin: 0 100px;
  padding: 25px 0;
`;

export interface LogoLinkProps {
  path?: string;
}

const LogoLink: React.FC<LogoLinkProps> = ({ path = "/" }) => (
  <RouterLink to={path}>
    <LogoContainer>
      <Logo />
    </LogoContainer>
  </RouterLink>
);

const CondensedLogoLink: React.FC<LogoLinkProps> = ({ path = "/" }) => (
  <RouterLink to={path}>
    <CondensedLogoContainer>
      <Logo />
    </CondensedLogoContainer>
  </RouterLink>
);

export { Link, LogoLink, Anchor, CondensedLogoLink };
