import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";

import { CenteredWithPadding } from "./widgets/content";
import { H1, H5 } from "./elements/headers";
import { Container } from "./widgets/page";
import { EmptyState } from "./widgets/emptyState";
import { CustomersAdd } from "./customersAdd";
import styled from "styled-components";
import { deleteCustomer, getCustomers } from "../services/customers";
import { Actions } from "./widgets/actions";
import { customerHasBookings } from "../services/bookings";
import { ErrorMessage } from "./elements/forms";

const CustomerItem = styled.div<{ isSelected?: boolean }>`
  margin-bottom: 10px;
  padding: 10px;

  background: ${props => (props.isSelected ? "#F5EDB7" : "#eeeeee")};
  border-radius: 5px;

  &:last-child {
    margin: 0;
  }
`;

const CustomerDetailContainer = styled.div<{ isSelected?: boolean }>`
  display: ${props => (props.isSelected ? "block" : "none")};
  margin-top: 10px;
  padding-top: 10px;

  border-top: 3px dotted #22252d;
`;

const CustomerTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
  grid-column-gap: 10px;
  margin-bottom: 5px;
`;

const CustomersIndex: React.FC<{
  initialized: boolean;
  customers: any[];
  selectedCustomerId: string;
  selectCustomer: (customerId: string) => void;
  clickAdd: () => void;
  clickEdit: (customerId: string) => void;
  clickDelete: (customerId: string) => void;
  error: string;
}> = ({
  initialized,
  customers,
  selectedCustomerId,
  selectCustomer,
  clickAdd,
  clickEdit,
  clickDelete,
  error
}) => (
  <div>
    <CenteredWithPadding>
      <H1>Customers</H1>
    </CenteredWithPadding>
    <Container>
      {initialized && !customers.length && (
        <EmptyState
          text="You don't have any customers yet"
          buttonText={"Add a customer"}
          icon="fas fa-users"
          onClick={clickAdd}
        />
      )}
      {customers.map((customer: any) => (
        <CustomerItem
          key={customer.id}
          onClick={() => selectCustomer(customer.id)}
          isSelected={customer.id === selectedCustomerId}
        >
          <strong>{`${customer.lastName}, ${customer.firstName}`}</strong>
          <CustomerDetailContainer
            isSelected={customer.id === selectedCustomerId}
          >
            <CustomerTable>
              <H5>Phone number</H5>
              <div>{customer.phoneNumber}</div>
              <H5>Email</H5>
              <div>{customer.email}</div>
            </CustomerTable>
            {error && <ErrorMessage>{error}</ErrorMessage>}
            <Actions
              clickEdit={() => clickEdit(customer.id)}
              clickDelete={() => clickDelete(customer.id)}
            />
          </CustomerDetailContainer>
        </CustomerItem>
      ))}
    </Container>
  </div>
);

const Customers: React.FC<{
  match: any;
  history: any;
  setIsLoading: (isLoading: boolean) => void;
  userDetails: any;
}> = ({ match, history, setIsLoading, userDetails }) => {
  const [initialized, setInitialized] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomerId, setSelectedCustomerId] = useState(null);
  const [error, setError] = useState("");

  const getAllCustomers = async () => {
    setIsLoading(true);
    const allCustomers = await getCustomers(userDetails.fieldId);
    setIsLoading(false);
    setInitialized(true);

    setCustomers(allCustomers);
  };

  useEffect(() => {
    getAllCustomers();
    setSelectedCustomerId(null);

    // eslint-disable-next-line
  }, [match.isExact]);

  const selectCustomer = (customerId: string) => {
    setError("");

    if (customerId === selectedCustomerId) {
      setSelectedCustomerId(null);

      return;
    }

    setSelectedCustomerId(customerId);
  };

  const clickAdd = () => history.push("/customers/add-customer");

  const clickEdit = (customerId: string) => {
    history.push(`/customers/edit-customer/${customerId}`);
  };

  const clickDelete = async (customerId: string) => {
    if (await customerHasBookings(userDetails.fieldId, customerId)) {
      setError(
        "This customer cannot be deleted as it is associated with one or more bookings."
      );

      return;
    }

    setIsLoading(true);
    await deleteCustomer(userDetails.fieldId, customerId);
    await getAllCustomers();
    setIsLoading(false);

    setSelectedCustomerId(null);
  };

  return (
    <div>
      <Switch key={match.path}>
        <Route
          exact
          path={match.path}
          render={() => (
            <CustomersIndex
              customers={customers}
              initialized={initialized}
              selectedCustomerId={selectedCustomerId}
              selectCustomer={selectCustomer}
              clickAdd={clickAdd}
              clickEdit={clickEdit}
              clickDelete={clickDelete}
              error={error}
            />
          )}
        />
        <Route
          path={`${match.path}/edit-customer/:customerId`}
          render={props => (
            <CustomersAdd
              {...props}
              title="Edit customer"
              setIsLoading={setIsLoading}
              userDetails={userDetails}
            />
          )}
        />
        <Route
          path={`${match.path}/add-customer`}
          render={props => (
            <CustomersAdd
              {...props}
              title="Add a customer"
              setIsLoading={setIsLoading}
              userDetails={userDetails}
            />
          )}
        />
      </Switch>
    </div>
  );
};

export { Customers };
