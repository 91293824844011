import * as firebase from "firebase/app";
import "firebase/auth";
import "firebase/functions";
import "firebase/firestore";
import "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyBL1QWDJyv_SDFonyczOGImf05zwTEK-R4",
  authDomain: "lets-go-paintball.firebaseapp.com",
  databaseURL: "https://lets-go-paintball.firebaseio.com",
  projectId: "lets-go-paintball",
  storageBucket: "lets-go-paintball.appspot.com",
  messagingSenderId: "1055122969863",
  appId: "1:1055122969863:web:31e4011d3c872a3a"
};

firebase.initializeApp(firebaseConfig);

export { firebase as default };
