import React from "react";

import { ErrorMessage, Input, InputProps } from "./forms";

export interface InputControlProps extends InputProps {
  errorMessage?: string;
  style?: any;
}

const InputControl: React.FC<InputControlProps> = ({
  errorMessage,
  type,
  disabled,
  placeholder,
  value,
  onFocus,
  onClick,
  onChange,
  onKeyDown,
  onBlur,
  style,
  autoComplete
}) => {
  return (
    <div>
      <Input
        autoComplete={autoComplete}
        hasError={!!errorMessage}
        type={type}
        disabled={disabled}
        placeholder={placeholder}
        onClick={onClick}
        onChange={onChange}
        onFocus={onFocus}
        value={value}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        style={style}
      />
      {!!errorMessage && (
        <ErrorMessage data-testid="InputControl__errorMessage">
          {errorMessage}
        </ErrorMessage>
      )}
    </div>
  );
};

export { InputControl };
