import React, { useState } from "react";
import { withRouter, Route } from "react-router-dom";
import { History } from "history";
import firebase from "../firebase";

import { H1 } from "./elements/headers";
import { Panel } from "./widgets/panel";
import { LogoLink } from "./elements/links";
import { InputControl } from "./elements/inputControl";
import { PrimaryButton } from "./elements/forms";
import { isValidEmail } from "../utils/validators";
import { P } from "./widgets/content";

export const ForgotPassword: React.FC<{ history: History }> = ({ history }) => {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  const attemptPasswordReset = async () => {
    const hasValidEmail = isValidEmail(email);

    if (!hasValidEmail) {
      setEmailError("Invalid email");

      return;
    }

    const forgotPassword = firebase.functions().httpsCallable("forgotPassword");

    forgotPassword({ email }).then(() => {
      history.push("/forgot-password/requested");
    });
  };

  return (
    <div>
      <LogoLink />
      <Panel>
        <Route
          exact
          path="/forgot-password"
          render={() => (
            <>
              <H1>Password reset</H1>
              <P>Submit an email below to request a password reset email.</P>
              <form onSubmit={e => e.preventDefault()}>
                <InputControl
                  errorMessage={emailError}
                  type="text"
                  placeholder="Email"
                  onChange={(e: any) => setEmail(e.target.value)}
                />
                <PrimaryButton onClick={attemptPasswordReset}>
                  Submit
                </PrimaryButton>
              </form>
            </>
          )}
        />
        <Route
          path="/forgot-password/requested"
          render={() => (
            <>
              <H1>Request complete</H1>
              <P>
                A password reset link will be delivered to
                <strong>{` ${email}`}</strong> if it is associated with an
                existing account.
              </P>
            </>
          )}
        />
      </Panel>
    </div>
  );
};

export default withRouter(ForgotPassword);
