import React from "react";
import styled from "styled-components";
import { DeleteButton, BasicPrimaryButton } from "../elements/forms";
import { ActionIcon } from "./icons";

const ActionsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 10px;
  margin-top: 15px;
`;

const Actions: React.FC<{
  clickDelete: () => void;
  clickEdit: () => void;
}> = ({ clickDelete, clickEdit }) => (
  <ActionsContainer>
    <DeleteButton
      onClick={e => {
        e.stopPropagation();
        clickDelete();
      }}
    >
      <ActionIcon className="fa fa-trash fa-sm" />
      {` Delete`}
    </DeleteButton>
    <BasicPrimaryButton
      onClick={e => {
        e.stopPropagation();
        clickEdit();
      }}
    >
      <ActionIcon className="fa fa-pencil-alt fa-sm" />
      {` Edit`}
    </BasicPrimaryButton>
  </ActionsContainer>
);

export { Actions };
