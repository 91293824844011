import React, { useState } from "react";
import styled from "styled-components";
import { withRouter, Link } from "react-router-dom";

import { ActionIcon } from "./widgets/icons";
import { Collapse } from "@material-ui/core";
import { signOutUser } from "../services/users";

const OutsideNavigationContainer = styled.div`
  position: fixed;
  bottom: 0;
  padding: 0 15px 10px;
  width: 100%;

  z-index: 10;
`;

const NavigationBackgroundContainer = styled.div`
  padding: 10px;

  background: #22252d;
  border-radius: 5px;
  color: #ffffff;
`;

const NavigationContainer = styled.div<{ disabled: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;

  ${props => (props.disabled ? "opacity: .35;" : "")}
`;

const NavItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  text-align: center;
`;

const NavItemLabel = styled.div`
  font-size: 0.7rem;
  margin-top: 2px;
`;

const CTAButton = styled.button`
  margin: auto;
  width: fit-content;
  padding: 10px 12px;

  background: #4498d8;
  border: 0;
  border-radius: 50%;
  color: #ffffff;
`;

const NavLink = styled(Link)`
  color: #ffffff;
  text-decoration: none;
`;

const MenuNavLink = styled(Link)`
  padding: 10px 15px;

  background: #22252d;
  border-radius: 5px;
  color: #ffffff;
  font-size: 0.85rem;
  text-decoration: none;
`;

const MenuContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin: 10px 0 0;
  padding: 10px 0;

  text-align: center;
`;

const NavigationComponent = () => {
  const [showAddMenu, setShowAddMenu] = useState(false);
  const [showMoreMenu, setShowMoreMenu] = useState(false);

  return (
    <OutsideNavigationContainer>
      <NavigationBackgroundContainer>
        <NavigationContainer disabled={showAddMenu || showMoreMenu}>
          <NavItem>
            <NavLink to="/bookings">
              <div>
                <i className="fas fa-clipboard" />
              </div>
              <NavItemLabel>Bookings</NavItemLabel>
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/packages">
              <i className="fas fa-box-open" />
              <NavItemLabel>Packages</NavItemLabel>
            </NavLink>
          </NavItem>
          <NavItem>
            <CTAButton
              onClick={() => setShowAddMenu(!showAddMenu)}
              onBlur={() => setTimeout(() => setShowAddMenu(false), 1)}
            >
              <i className="fas fa-plus" />
            </CTAButton>
          </NavItem>
          <NavItem>
            <NavLink to="/equipment">
              <i className="fas fa-tags" />
              <NavItemLabel>Equipment</NavItemLabel>
            </NavLink>
          </NavItem>
          <NavItem
            tabIndex={0}
            onClick={() => setShowMoreMenu(!showMoreMenu)}
            onBlur={() => setTimeout(() => setShowMoreMenu(false), 1)}
          >
            <i className="fas fa-ellipsis-h" />
            <NavItemLabel>More</NavItemLabel>
          </NavItem>
        </NavigationContainer>
      </NavigationBackgroundContainer>
      <Collapse in={showAddMenu}>
        <MenuContainer>
          <div />
          <MenuNavLink to="/bookings/add-booking">
            <ActionIcon className="fas fa-clipboard" /> Add a booking
          </MenuNavLink>
          <div />
          <MenuNavLink to="/packages/add-package">
            <ActionIcon className="fas fa-box-open" /> Add a package
          </MenuNavLink>
          <div />
          <MenuNavLink to="/equipment/add-equipment">
            <ActionIcon className="fas fa-tags" /> Add equipment
          </MenuNavLink>
          <div />
          <MenuNavLink to="/customers/add-customer">
            <ActionIcon className="fas fa-users" /> Add a customer
          </MenuNavLink>
        </MenuContainer>
      </Collapse>
      <Collapse in={showMoreMenu}>
        <MenuContainer>
          <div />
          <MenuNavLink to="/field">
            <ActionIcon className="fas fa-store-alt" /> Field
          </MenuNavLink>
          <div />
          <MenuNavLink to="/customers">
            <ActionIcon className="fas fa-users" /> Customers
          </MenuNavLink>
          <div />
          <MenuNavLink to="/account-settings">
            <ActionIcon className="fas fa-user-circle" /> Account settings
          </MenuNavLink>
          <div />
          <MenuNavLink to="/" onClick={signOutUser}>
            <ActionIcon className="fas fa-door-open" /> Log out
          </MenuNavLink>
        </MenuContainer>
      </Collapse>
    </OutsideNavigationContainer>
  );
};

const Navigation = withRouter(NavigationComponent);

export { Navigation };
